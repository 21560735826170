import React from 'react';
import { SvgIcon } from '../elements';

const Message = React.forwardRef((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M19.078 2.119h-14.156c-2.714 0-4.922 2.208-4.922 4.922v7.602c0 2.166 1.422 4.052 3.482 4.656l2.377 2.377c0.132 0.132 0.311 0.206 0.497 0.206s0.365-0.074 0.497-0.206l2.181-2.181h10.044c2.714 0 4.922-2.208 4.922-4.922v-7.532c0-2.714-2.208-4.922-4.922-4.922zM22.594 14.573c0 1.938-1.577 3.516-3.516 3.516h-10.335c-0.186 0-0.365 0.074-0.497 0.206l-1.889 1.889-2.012-2.012c-0.090-0.090-0.203-0.154-0.327-0.185-1.537-0.383-2.611-1.757-2.611-3.344v-7.602c0-1.939 1.577-3.516 3.516-3.516h14.156c1.938 0 3.516 1.577 3.516 3.516v7.532z"></path>
      <path d="M16.465 8.862h-8.929c-0.388 0-0.703 0.315-0.703 0.703s0.315 0.703 0.703 0.703h8.929c0.388 0 0.703-0.315 0.703-0.703s-0.315-0.703-0.703-0.703z"></path>
      <path d="M16.465 11.346h-8.929c-0.388 0-0.703 0.315-0.703 0.703s0.315 0.703 0.703 0.703h8.929c0.388 0 0.703-0.315 0.703-0.703s-0.315-0.703-0.703-0.703z"></path>
    </SvgIcon>
  );
});

export default Message;
