import React from 'react';
import { Select } from 'components/elements';
import { useSubCategories } from 'hooks/data';

const SubCategoriesAutocomplete = ({
  isMulti,
  disabled,
  isInvalid,
  categoryId,
  value,
  onChange,
}) => {
  const { isLoading, subCategories } = useSubCategories({ categoryId });

  const innerValue = typeof value === 'object' ? value : subCategories.find(s => s.id === value);

  return (
    <Select
      isSearchable
      isMulti={isMulti}
      isLoading={isLoading}
      isInvalid={isInvalid}
      disabled={disabled}
      options={subCategories}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.id}
      value={innerValue}
      onChange={onChange}
    />
  );
};

export default SubCategoriesAutocomplete;
