import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

yup.addMethod(yup.number, 'allowEmptyString', function (replacement) {
  return this.transform(function (value, originalValue) {
    return originalValue === '' ? replacement : +originalValue;
  });
});

yup.addMethod(yup.string, 'phone', function () {
  return this.test({
    name: 'phone-test',
    test: value => {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value.startsWith('+') ? value : `+${value}`);
    },
    message: 'Неверный формат значения',
  });
});

const TIME_REGEXP = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

yup.addMethod(yup.string, 'time', function () {
  return this.test({
    name: 'time-test',
    test: value => {
      if (!value) {
        return true;
      }
      return TIME_REGEXP.test(value);
    },
    message: 'Неверный формат значения',
  });
});

yup.addMethod(yup.object, 'period', function () {
  return this.test({
    name: 'period-test',
    test: value => {
      if (!value) {
        return false;
      }
      return TIME_REGEXP.test(value.start) && TIME_REGEXP.test(value.finish);
    },
    message: 'Неверный формат значения',
  });
});

yup.addMethod(yup.object, 'straight', function () {
  return this.test({
    name: 'straight-test',
    test: value => {
      if (!value) {
        return false;
      }
      return dayjs(value.start, 'HH:mm').isBefore(dayjs(value.finish, 'HH:mm'));
    },
    message: '"С" должно быть меньше чем "По"',
  });
});

yup.setLocale({
  mixed: {
    required: 'Это поле обязательно для заполнения',
    notType: ({ type }) => {
      switch (type) {
        case 'number':
          return 'Значение должно быть числом';
        case 'string':
          return 'Значение должно быть строкой';
        default:
          return 'Неверный тип значения';
      }
    },
  },
  number: {
    default: 'Значение должно быть числом',
    integer: 'Значение должно быть целым',
    positive: 'Значение должно быть положительным',
    min: ({ min }) => `Значение должно быть не менее ${min}`,
    max: ({ max }) => `Значение должно быть не более ${max}`,
  },
  string: {
    default: 'Значение должно быть строкой',
    matches: 'Неверный формат значения',
    email: 'Неверный формат значения',
  },
  array: {
    min: ({ min }) => `Поле должно содержать не менее ${min} элемента(ов)`,
    max: ({ max }) => `Поле должно содержать не более ${max} элемента(ов)`,
  },
});
