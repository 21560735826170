import React from 'react';
import { Pagination, Form } from 'react-bootstrap';
import styles from './GridPageSize.module.scss';

const PageSize = ({
  pageSizeOptions,
  rowsCount,
  page,
  pagesCount,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onLoadMore,
}) => {
  if (pagesCount < 2 || onLoadMore) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Form.Control
        custom
        as="select"
        value={pageSize}
        onChange={e => onPageSizeChange(+e.target.value)}
      >
        {pageSizeOptions.map((pageSizeOption, index) => (
          <option key={index}>{pageSizeOption}</option>
        ))}
      </Form.Control>
      из
      <span>{rowsCount}</span>
      <Pagination>
        <Pagination.Prev disabled={page === 1} onClick={() => onPageChange(page - 1)} />
        <Pagination.Next disabled={page === pagesCount} onClick={() => onPageChange(page + 1)} />
      </Pagination>
    </div>
  );
};

export default PageSize;
