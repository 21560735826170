import { useQuery } from 'react-query';
import api from 'utils/api';

const useDarkstores = ({ partnerId, availableDarkstores, onSuccess }) => {
  const { data } = useQuery(
    ['darkstores', { partnerId, availableDarkstores }],
    async () => {
      const partnerDarkstores = await api.get('admin/darkstores', { partnerId });
      if (availableDarkstores === 'all') {
        return partnerDarkstores;
      }
      return partnerDarkstores.filter(darkstore => availableDarkstores.includes(darkstore.id));
    },
    { onSuccess },
  );

  return { darkstores: data ?? [] };
};

export default useDarkstores;
