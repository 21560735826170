import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import api from 'utils/api';

const UploadButton = ({ children, className, id, disabled, type, onUpload }) => {
  const { isLoading, mutate } = useMutation(
    formData => api.post('admin/upload', formData, { 'content-type': 'multypart/form-data' }),
    { onSuccess: result => onUpload(result.path) },
  );

  const onChange = e => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    mutate(formData);
  };

  return (
    <>
      <label
        className={clsx('btn', 'btn-primary', className, disabled && 'disabled')}
        htmlFor={`file-upload-btn-${id}`}
      >
        {isLoading && (
          <Spinner as="span" animation="border" size="sm" role="status" variant="light" />
        )}
        {children}
      </label>
      <input
        id={`file-upload-btn-${id}`}
        disabled={disabled}
        type="file"
        onChange={onChange}
        hidden
      />
    </>
  );
};

export default UploadButton;
