import { useMemo } from 'react';
import { copyToClipboard } from 'utils/helpers';

const makeRowMenu = ({ t, push, modalState }) => [
  {
    title: t('shared:buttons.cancel'),
    icon: 'cancel',
    onClick: row => {
      modalState.set({
        isOpened: true,
        key: 'declineSupply',
        supplyId: row.id,
        supplyNum: row.num,
      });
    },
  },
  {
    title: t('shared:buttons.copy'),
    icon: 'copy',
    onClick: row => push(`/supplies/new?templateId=${row.id}`),
  },
  {
    title: t('shared:buttons.copyLink'),
    icon: 'link',
    onClick: row => copyToClipboard(`${window.location.origin}/supplies/${row.id}`),
  },
  {
    title: t('shared:buttons.delete'),
    icon: 'delete',
    onClick: row => {
      modalState.set({
        isOpened: true,
        key: 'deleteSupply',
        supplyId: row.id,
        supplyNum: row.num,
      });
    },
  },
];

const useRowMenu = ({ t, push, modalState }) => {
  const rowMenu = useMemo(() => makeRowMenu({ t, push, modalState }), [t, push, modalState]);

  return rowMenu;
};

export default useRowMenu;
