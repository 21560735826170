import React, { useState, useEffect } from 'react';
import { Form, Spinner, Modal, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import { Close as CloseIcon } from 'components/icons';
import api from 'utils/api';
import { parseDate } from 'utils/helpers';
import styles from './DeliveryToModal.module.scss';

const useDeliveryTimes = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['delivery-times'],
    queryFn: async () => {
      const data = await api.get(`/deliveryTime/${localStorage.getItem('darkstoreId')}`);
      return [
        { value: '00:00:00', label: 'Сейчас' },
        ...data.map(item => ({ value: `${item[0] > 2 ? 0 : ''}${item}:00`, label: item })),
      ];
    },
  });
  return { isLoading, times: data ?? [] };
};

const DeliveryToModal = ({
  orderId,
  dateDelivery,
  timeDelivery,
  dateCreate,
  onSuccess,
  onCancel,
}) => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('0');
  const { isLoading, times = [] } = useDeliveryTimes();

  const today = dateCreate ? parseDate(dateCreate) : new Date();
  const tomorrow = dateCreate ? parseDate(dateCreate) : new Date();
  tomorrow.setDate(today.getDate() + 1);

  useEffect(() => {
    if (dateDelivery) {
      setDate(parseDate(dateDelivery));
    }
    if (timeDelivery) {
      setTime(timeDelivery);
    }
    setTimeout(() => {
      const element = document.getElementById(timeDelivery);
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, 100);
  }, [orderId, dateDelivery, timeDelivery]);

  return (
    <Modal show centered size="md" onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>Время доставки</Modal.Title>
        <CloseIcon size={20} onClick={onCancel} />
      </Modal.Header>
      {isLoading ? (
        <Modal.Body>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <div className={styles.tabs}>
              <span
                className={clsx(
                  styles.tab,
                  date.getDate() === today.getDate() && styles.tabSelected,
                )}
                onClick={() => setDate(today)}
              >
                Сегодня
              </span>
              <span
                className={clsx(
                  styles.tab,
                  date.getDate() === tomorrow.getDate() && styles.tabSelected,
                )}
                onClick={() => setDate(tomorrow)}
              >
                Завтра
              </span>
            </div>
            <div className={styles.radios}>
              {times.map(item => {
                if (item.value === '00:00:00' && date.getDate() === tomorrow.getDate()) {
                  return null;
                }
                const selected = item.value === time;
                return (
                  <Form.Check
                    custom
                    className={styles.radio}
                    id={item.value}
                    key={item.value}
                    type="radio"
                    name="deliveryTime"
                    checked={selected}
                    label={item.label}
                    onChange={() => setTime(item.value)}
                  />
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onCancel}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                onSuccess(
                  { orderId, dateDelivery: date.toISOString(), timeDelivery: time },
                  { orderId, dateDelivery, timeDelivery },
                );
              }}
            >
              Сохранить
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DeliveryToModal;
