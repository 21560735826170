import React from 'react';
import { SvgIcon } from '../elements';

const Registry = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.1 2.844h-0.496c-0.195-0.748-0.868-1.304-1.678-1.304h-2.36c-0.498-0.912-1.455-1.539-2.566-1.539s-2.068 0.628-2.566 1.539h-2.36c-0.81 0-1.483 0.556-1.678 1.304h-0.495c-0.96 0-1.739 0.779-1.739 1.739v17.679c0 0.96 0.779 1.739 1.739 1.739h14.199c0.959 0 1.739-0.779 1.739-1.739v-17.679c-0-0.962-0.778-1.739-1.739-1.739zM12 1.684c0.69 0 1.25 0.56 1.25 1.251s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25s0.56-1.251 1.25-1.251zM6.947 18.348c-0.481 0-0.869-0.389-0.869-0.87s0.388-0.869 0.869-0.869c0.482 0 0.869 0.389 0.869 0.869s-0.389 0.87-0.869 0.87zM6.947 14.001c-0.481 0-0.869-0.389-0.869-0.869s0.388-0.869 0.869-0.869c0.482 0 0.869 0.389 0.869 0.869s-0.389 0.869-0.869 0.869zM6.947 9.654c-0.481 0-0.869-0.389-0.869-0.869s0.388-0.869 0.869-0.869c0.482 0 0.869 0.389 0.869 0.869s-0.389 0.869-0.869 0.869zM17.44 18.348h-7.635c-0.482 0-0.869-0.389-0.869-0.87s0.388-0.869 0.869-0.869h7.635c0.481 0 0.869 0.389 0.869 0.869s-0.388 0.87-0.869 0.87zM17.44 14.001h-7.635c-0.482 0-0.869-0.389-0.869-0.869s0.388-0.869 0.869-0.869h7.635c0.481 0 0.869 0.389 0.869 0.869s-0.388 0.869-0.869 0.869zM17.44 9.654h-7.635c-0.482 0-0.869-0.389-0.869-0.869s0.388-0.869 0.869-0.869h7.635c0.481 0 0.869 0.389 0.869 0.869s-0.388 0.869-0.869 0.869z"></path>
    </SvgIcon>
  );
};

export default Registry;
