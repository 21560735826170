import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import dropzoneFile from 'img/dropzoneFile.svg';
import dropzoneImg from 'img/dropzoneImg.svg';
import api from 'utils/api';
import { getImageUrl } from 'utils/helpers';
import message from 'utils/message';
import { Close as CloseIcon } from '../../icons';
import styles from './Dropzone.module.scss';

const Dropzone = ({
  accept = 'image/*',
  title = 'Забросьте файл изображения или кликните для загрузки с компьютера',
  maxSize = 153600,
  type = 'image',
  value,
  onUpload = () => {},
}) => {
  const { isLoading, mutate } = useMutation(
    formData => api.post('admin/upload', formData, { 'content-type': 'multypart/form-data' }),
    { onSuccess: result => onUpload(result.path) },
  );

  const onChange = file => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      mutate(formData);
    } else {
      onUpload(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles: 1,
    multiple: false,
    maxSize,
    onDropAccepted: ([file]) => onChange(file),
    onDropRejected: ([fileRejection]) => {
      const { errors } = fileRejection;
      errors.forEach(error => {
        switch (error.code) {
          case 'file-invalid-type':
            message('Неверный формат файла', { type: 'error' });
            break;
          case 'file-too-large':
            message('Слишком большой размер файла', { type: 'error' });
            break;
          default:
            message('Неизвестная ошибка при загрузке файла', { type: 'error' });
            break;
        }
      });
    },
  });

  if (isLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <section>
      {value ? (
        <ul className={styles.files}>
          <li>
            <img src={dropzoneFile} alt="dropzoneFile" />
            <a href={getImageUrl(value)} target="_blank" rel="noreferrer">
              {value.split('/').pop()}
            </a>
            <CloseIcon size={6} onClick={() => onChange('')} />
          </li>
        </ul>
      ) : (
        <div {...getRootProps({ className: styles.drop })}>
          <input {...getInputProps()} />
          <img src={dropzoneImg} alt="dropzoneImg" />
          <p>{title}</p>
        </div>
      )}
    </section>
  );
};

export default Dropzone;
