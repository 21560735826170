import { useMutation, useQueryClient } from 'react-query';
import api from 'utils/api';
import message from 'utils/message';

const useDeleteSupply = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(supplyId => api.delete(`admin/supplies/${supplyId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('admin/supplies');
      queryClient.invalidateQueries('providers/supplies');
      message('Поставка удалена', { type: 'success' });
      onSuccess();
    },
    onError: error => message(error.message, { type: 'error' }),
  });

  return {
    isDeleting: isLoading,
    deleteSupply: mutate,
  };
};

export default useDeleteSupply;
