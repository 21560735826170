// use scroll polyfill for Sarafi
import { polyfill } from 'seamless-scroll-polyfill';
import { isSafari } from 'utils/helpers';

if (isSafari()) {
  polyfill();
}

// import ResizeObserver polyfill if it not supported
(async () => {
  if ('ResizeObserver' in window === false) {
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  }
})();
