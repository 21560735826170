import React from 'react';
import clsx from 'clsx';
import { Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';
import styles from './DataLanguageChanger.module.scss';

const LanguageChanger = ({ className }) => {
  const { languages, language, setLanguage } = useSettingsContext();

  const selectedOption = languages.find(languageOption => languageOption.key === language);

  if (languages.length <= 1) {
    return null;
  }

  return (
    <div className={clsx(styles.root, className)}>
      <span className={styles.title}>Язык данных</span>
      <Select
        options={languages}
        value={selectedOption}
        getOptionValue={option => option.key}
        onChange={option => setLanguage(option.key)}
      />
    </div>
  );
};

export default LanguageChanger;
