import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_STATUSES_ENUM } from 'constants/order';

const useOrderStatuses = () => {
  const { t } = useTranslation();
  const orderStatuses = useMemo(
    () =>
      ORDER_STATUSES_ENUM.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );
  return { orderStatuses };
};

export default useOrderStatuses;
