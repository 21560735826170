import { useMutation, useQueryClient } from 'react-query';
import api from 'utils/api';
import message from 'utils/message';

const useDeclineSupply = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    supplyId => api.put('admin/supplies', { id: supplyId, status: 3 }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admin/supplies');
        queryClient.invalidateQueries('providers/supplies');
        message('Поставка отменена', { type: 'success', withIcon: true });
        onSuccess();
      },
      onError: error => message(error.message, { type: 'error' }),
    },
  );

  return {
    isDeclining: isLoading,
    declineSupply: mutate,
  };
};

export default useDeclineSupply;
