import React, { useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Calendar as CalendarIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'components/icons';
import { formatDate } from 'utils/formatters';
import { capitalize } from 'utils/helpers';
import styles from './DatePicker.module.scss';

const getInputValue = (isDateRange, value, dateFormat) => {
  if (!value) {
    return '';
  }
  if (isDateRange) {
    return `${formatDate(value[0], 'DD.MM')} - ${formatDate(value[1], 'DD.MM.YYYY')}`;
  }
  return formatDate(value, dateFormat);
};

const getDefaultView = type => {
  if (type === 'year') {
    return 'decade';
  }
  if (type === 'month') {
    return 'year';
  }
  return 'month';
};

const Datepicker = ({
  className,
  isDateRange,
  isInvalid,
  disabled,
  type,
  dateFormat = 'DD.MM.YYYY',
  size = 'normal',
  placeholder = 'Выберите дату',
  minDate,
  maxDate,
  value,
  onChange,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [referenceElement, setReferenceElement] = useState(null);

  const onClick = disabled ? undefined : e => setReferenceElement(e.currentTarget);

  return (
    <>
      <div
        className={clsx(styles.root, size && styles[`root${capitalize(size)}`], className)}
        onClick={onClick}
      >
        <CalendarIcon className={styles.icon} color="default" />
        <input
          className={clsx(styles.input, isInvalid && styles.inputIsInvalid)}
          readOnly
          disabled={disabled}
          type="text"
          autoComplete="off"
          name="date"
          aria-label="date"
          placeholder={placeholder}
          value={getInputValue(isDateRange, value, dateFormat)}
        />
      </div>

      <Overlay
        flip
        rootClose
        show={!!referenceElement}
        target={referenceElement}
        placement="bottom-start"
        popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
        onHide={() => setReferenceElement(null)}
      >
        <Popover>
          <Calendar
            locale={language}
            prevLabel={<ChevronLeftIcon />}
            nextLabel={<ChevronRightIcon />}
            defaultView={getDefaultView(type)}
            selectRange={isDateRange}
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={changedValue => {
              onChange(changedValue);
              setReferenceElement(null);
            }}
            onClickMonth={changedValue => {
              if (type === 'month') {
                onChange(changedValue);
                setReferenceElement(null);
              }
            }}
            onClickYear={changedValue => {
              if (type === 'year') {
                onChange(changedValue);
                setReferenceElement(null);
              }
            }}
          />
        </Popover>
      </Overlay>
    </>
  );
};

export default Datepicker;
