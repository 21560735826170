import React, { useState } from 'react';
import { useDebouncedValue } from 'hooks';
import { Select } from 'components/elements';
import useAddresses from './useAddresses';

const AddressAutocomplete = ({ isInvalid, disabled, placeholder, value, onChange }) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);

  const { isLoading, addresses } = useAddresses({ query: debouncedQuery });

  return (
    <Select
      isSearchable
      isFilterable={false}
      isInvalid={isInvalid}
      isLoading={isLoading}
      disabled={disabled}
      placeholder={placeholder}
      options={addresses.map(a => ({ label: a.name, value: a.geoid }))}
      value={value}
      onChange={option => {
        const address = addresses.find(a => option.geoid === a.value);
        if (address) {
          const { name, lat, lon } = address;
          const [street, house, city = 'Минск', country = 'Беларусь'] = name
            .split(',')
            .map(v => v.trim());
          onChange({ street, house, city, country, lat, lon });
        }
      }}
      onInputValueChange={setQuery}
    />
  );
};

export default AddressAutocomplete;
