import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHODS } from 'constants/order';

const usePaymentMethods = () => {
  const { t } = useTranslation();
  const paymentMethods = useMemo(
    () =>
      PAYMENT_METHODS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );
  return { paymentMethods };
};

export default usePaymentMethods;
