import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { DarkstoresSelect } from 'components';
import { Pin as PinIcon } from 'components/icons';
import { useSettingsContext } from 'contexts/SettingsContext';
import styles from './Darkstores.module.scss';

const Darkstores = ({ isMinimized }) => {
  const { darkstores, darkstoreId, setDarkstoreId } = useSettingsContext();
  const selectedDarkstore = darkstores.find(darkstore => darkstore.id === darkstoreId);

  if (isMinimized) {
    return (
      <div className={styles.root}>
        <OverlayTrigger
          placement="auto"
          overlay={
            <Popover>
              <Popover.Content>{selectedDarkstore.name}</Popover.Content>
            </Popover>
          }
        >
          <PinIcon color="primary" size={24} />
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <PinIcon color="primary" size={24} />
      <DarkstoresSelect
        value={selectedDarkstore}
        getOptionClassName={option => {
          if (option.zoneStatus === 2) {
            return styles.increasedDemand;
          }
          if (option.zoneStatus === 3) {
            return styles.badWeather;
          }
          return styles.default;
        }}
        onChange={darkstore => setDarkstoreId(darkstore.id)}
      />
    </div>
  );
};

export default Darkstores;
