import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from 'components/icons';
import useDeclineSupply from './useDeclineSupply';

const SupplyDeclineModal = ({ isOpened, supplyId, supplyNum, onClose }) => {
  const { t } = useTranslation();

  const { isDeclining, declineSupply } = useDeclineSupply({ onSuccess: onClose });

  return (
    <Modal show={isOpened} centered size="md" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{t('supplies:supplyDeclineModal.title')}</Modal.Title>
        <CloseIcon size={20} onClick={onClose} />
      </Modal.Header>
      {isDeclining ? (
        <>
          <Modal.Body>
            <Spinner animation="border" variant="primary" />
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body>{t('supplies:supplyDeclineModal.text', { supplyNum })}</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              {t('shared:buttons.no')}
            </Button>
            <Button onClick={() => declineSupply(supplyId)}>{t('shared:buttons.yes')}</Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default SupplyDeclineModal;
