import React from 'react';
import clsx from 'clsx';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'components/icons';
import { useAuthContext } from 'contexts/AuthContext';
import noAvatar from 'img/noAvatar.png';
import styles from './Header.module.scss';

const Header = ({ isSidebarOpened, setIsSidebarOpened }) => {
  const {
    user: { name, avatar },
  } = useAuthContext();

  return (
    <header className={clsx(styles.root, isSidebarOpened && styles.rootDrawerOpened)}>
      {isSidebarOpened ? (
        <ChevronLeftIcon size={20} onClick={() => setIsSidebarOpened(false)} />
      ) : (
        <ChevronRightIcon size={20} onClick={() => setIsSidebarOpened(true)} />
      )}
      <div className={styles.right}>
        <img className={styles.avatar} src={avatar || noAvatar} alt="avatar" />
        <span className={styles.user}>{name}</span>
      </div>
    </header>
  );
};

export default Header;
