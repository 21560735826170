const CURRENCIES = ['р', 'р', 'грн', '₸', 'zł'];

const getInitialPartnerId = partnerId => {
  const savedPartnerId = localStorage.getItem('partnerId');
  if (!savedPartnerId || partnerId !== Number(savedPartnerId)) {
    localStorage.setItem('partnerId', partnerId);
  }
  return partnerId;
};

const getInitialDarkstoreId = darkstores => {
  const savedDarkstoreId = localStorage.getItem('darkstoreId');
  if (savedDarkstoreId && darkstores.some(darkstore => darkstore.id === Number(savedDarkstoreId))) {
    return Number(savedDarkstoreId);
  }
  const darkstoreId = darkstores[0].id;
  localStorage.setItem('darkstoreId', darkstoreId);
  return darkstoreId;
};

const getInitialLanguage = languages => {
  const savedLanguage = localStorage.getItem('language');
  if (savedLanguage && languages.some(language => language.key === savedLanguage)) {
    return savedLanguage;
  }
  const languageKey = languages[0].key;
  localStorage.setItem('language', languageKey);
  return languageKey;
};

const getInitialSettings = ({ partnerId, darkstores, languages }) => {
  return {
    partnerId: getInitialPartnerId(partnerId),
    darkstoreId: getInitialDarkstoreId(darkstores),
    language: getInitialLanguage(languages),
    currency: CURRENCIES[partnerId] || 'р',
  };
};

export { getInitialSettings };
