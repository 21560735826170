import { useContext } from 'react';
import SettingsContext from './SettingsContext';

const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('Use useSettingsContext only inside SettingsContextProvider');
  }

  return context;
};

export default useSettingsContext;
