import React from 'react';
import { Select } from 'components/elements';
import { useCategories } from 'hooks/data';

const CategoriesAutocomplete = ({
  isMulti,
  disabled,
  isInvalid,
  darkstoreId,
  placeholder,
  value,
  onChange,
}) => {
  const { isLoading, categories } = useCategories({ darkstoreId, enabled: !disabled });

  const innerValue = typeof value === 'object' ? value : categories.find(c => c.id === value);

  return (
    <Select
      isSearchable
      isMulti={isMulti}
      isLoading={isLoading}
      isInvalid={isInvalid}
      disabled={disabled}
      placeholder={placeholder}
      options={categories}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      value={innerValue}
      onChange={onChange}
    />
  );
};

export default CategoriesAutocomplete;
