import React from 'react';
import { SvgIcon } from '../elements';

const Check = props => {
  return (
    <SvgIcon {...props}>
      <path d="M7.627 17.287l-5.69-5.69-1.937 1.924 7.627 7.627 16.373-16.373-1.924-1.924-14.449 14.435z"></path>
    </SvgIcon>
  );
};

export default Check;
