import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './UpDownButton.module.scss';

const UpDownButton = ({ value, minValue, maxValue, onChange }) => (
  <div className={styles.root}>
    <Button disabled={value <= minValue} onClick={() => onChange(value - 1)}>
      {' '}
      -{' '}
    </Button>
    {value}
    <Button disabled={value >= maxValue} onClick={() => onChange(value + 1)}>
      {' '}
      +{' '}
    </Button>
  </div>
);

export default UpDownButton;
