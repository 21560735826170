import { useEffect, useState } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { t } from 'i18next';

const remapOptions = ({
  options,
  selectedItems,
  getOptionLabel,
  getOptionValue,
  getOptionClassName,
}) =>
  options.map(option => {
    const value = getOptionValue(option);

    const items = option.options
      ? remapOptions({
          options: option.options,
          selectedItems,
          getOptionLabel,
          getOptionValue,
          getOptionClassName,
        })
      : null;

    return {
      isSelected: selectedItems.some(selectedItem => selectedItem.value === value),
      label: getOptionLabel(option),
      value,
      className: getOptionClassName(option),
      items,
      __data: option,
    };
  });

const filterOptions = (options, inputValue) =>
  options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()));

const prepareItems = ({
  isSearchable,
  isMulti,
  isFilterable,
  options,
  inputValue,
  selectedItems,
  getOptionLabel,
  getOptionValue,
  getOptionClassName,
}) => {
  const items =
    isSearchable && isFilterable
      ? filterOptions(
          remapOptions({
            options,
            selectedItems,
            getOptionLabel,
            getOptionValue,
            getOptionClassName,
          }),
          inputValue,
        )
      : remapOptions({
          options,
          selectedItems,
          getOptionLabel,
          getOptionValue,
          getOptionClassName,
        });

  if (isMulti) {
    return [
      {
        label: t('shared:all'),
        value: '__all__',
        isSelected: items.length > 0 && items.length === selectedItems.length,
      },
      ...items,
    ];
  }

  return items;
};

const prepareSelectedItems = ({ value, getOptionLabel, getOptionValue, getOptionClassName }) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return value.map(item => ({
        label: getOptionLabel(item),
        value: getOptionValue(item),
        className: getOptionClassName(item),
        __data: item,
      }));
    }

    return [];
  }

  if (value) {
    return [
      {
        label: getOptionLabel(value),
        value: getOptionValue(value),
        className: getOptionClassName(value),
        __data: value,
      },
    ];
  }

  return [];
};

const useSelect = ({
  isSearchable,
  isMulti,
  options,
  value,
  isFilterable,
  getOptionLabel,
  getOptionValue,
  getOptionClassName,
  onChange,
  onInputValueChange,
}) => {
  const [popperTarget, setPopperTarget] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const clearInputValue = () => setInputValue('');

  useEffect(() => {
    if (onInputValueChange) {
      onInputValueChange(inputValue);
    }
  }, [inputValue, onInputValueChange]);

  const getRootProps = props => {
    return {
      ...props,
      ref: ref => setPopperTarget(ref),
    };
  };

  const getPopperProps = props => {
    return {
      ...props,
      show: isMenuOpen,
      target: popperTarget,
      style: {
        width: popperTarget ? popperTarget.getBoundingClientRect().width : 0,
        maxWidth: popperTarget ? popperTarget.getBoundingClientRect().width : 0,
      },
    };
  };

  const selectedItems = prepareSelectedItems({
    value,
    getOptionLabel,
    getOptionValue,
    getOptionClassName,
  });

  const items = prepareItems({
    isSearchable,
    isMulti,
    isFilterable,
    options,
    inputValue,
    selectedItems,
    getOptionLabel,
    getOptionValue,
    getOptionClassName,
  });

  const {
    // getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    setSelectedItems,
  } = useMultipleSelection({
    selectedItems,
    itemToString: item => (item ? item.label : ''),
    stateReducer: (state, action) => {
      const { type, changes, selectedItem } = action;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem: {
          return {
            ...changes,
            selectedItems: state.selectedItems.filter(item => item.value !== selectedItem.value),
          };
        }
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItems }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
          if (isMulti) {
            onChange(
              selectedItems.map(item => item.__data),
              { clearInputValue },
            );
            break;
          }
          onChange(selectedItems[0].__data, { clearInputValue });
          break;
        default:
          break;
      }
    },
  });

  const {
    isOpen: isMenuOpen,
    highlightedIndex,
    // getLabelProps,
    getComboboxProps,
    getToggleButtonProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    openMenu,
  } = useCombobox({
    inputValue,
    // menu with sections
    items: items[0] && items[0].items ? items.map(item => item.items).flat() : items,
    selectedItem: null,
    itemToString: item => (item ? item.label : ''),
    stateReducer: (state, action) => {
      const { changes, type } = action;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: isMulti, // keep the menu open after selection
            highlightedIndex: isMulti ? state.highlightedIndex : changes.highlightedIndex, // keep highlightedIndex after selection
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, inputValue, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          if (!isMenuOpen) {
            clearInputValue();
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            if (!isMulti) {
              setInputValue(selectedItem.label);
              setSelectedItems([selectedItem]);
              return;
            }

            clearInputValue();

            if (selectedItem.value === '__all__') {
              setSelectedItems(
                selectedItem.isSelected ? [] : items.filter(item => item.value !== '__all__'),
              );
              return;
            }

            if (selectedItem.isSelected) {
              removeSelectedItem(selectedItem);
              return;
            }

            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  return {
    isMenuOpen,
    items,
    selectedItems,
    highlightedIndex,
    getRootProps,
    getPopperProps,
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getDropdownProps,
    openMenu,
    setInputValue,
    clearInputValue,
  };
};

export default useSelect;
