import dayjs from 'dayjs';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

export const formatDeliveryDateTime = (dateDelivery, timeDelivery, dateCreate) => {
  let deliveryTo;
  if (timeDelivery === '00:00:00') {
    deliveryTo = 'Сейчас';
  } else {
    // prettier-ignore
    const dayString = dayjs(dateDelivery).day() === dayjs(dateCreate).day() ? 'Сегодня' : 'Завтра';
    deliveryTo = `${dayString}, ${timeDelivery.slice(0, 5)}`;
  }
  return deliveryTo;
};

export const formatPhone = str => {
  const cleaned = str.replace(/\D/g, '');
  const phoneStr = `+${cleaned}`;
  const isValid = isValidPhoneNumber(phoneStr);
  if (!isValid) {
    return str;
  }
  const phone = parsePhoneNumber(phoneStr);
  return phone.formatInternational();
};

export const formatDate = (date, format) => {
  const tmp = dayjs(date);
  const isValid = tmp.isValid();
  if (!isValid) {
    console.warn(`Invalid date: ${date}, return formatted current date string`);
    return dayjs().format(format);
  }
  return tmp.format(format);
};
