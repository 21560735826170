import { DEFAULT_COL_DEF, SORT_ORDERS } from './constants';

const calculateLeft = (column, index, colDefs) => {
  return column.isSticky
    ? colDefs
        .slice(0, index)
        .reduce((acc, column) => (acc += column.width || DEFAULT_COL_DEF.width), 0)
    : 0;
};

/*
TODO:
  - refacor
  - add header cell text wraping  right styles
*/

export const prepareColumns = colDefs => {
  const headerGroups = [[]];
  const columns = [];

  const multipleGroups = colDefs.some(column => column.columns);

  if (multipleGroups) {
    headerGroups.push([]);

    colDefs.forEach(column => {
      if (column.columns) {
        headerGroups[0].push({
          ...column,
          isSortable: false,
          width: column.columns.reduce(
            (acc, innerColumn) => acc + (innerColumn.width || DEFAULT_COL_DEF.width),
            0,
          ),
        });
        column.columns.forEach(innerColumn => {
          columns.push(innerColumn);
          headerGroups[1].push(innerColumn);
        });
      } else {
        columns.push(column);
        headerGroups[0].push({ ...column, header: '' });
        headerGroups[1].push({
          ...column,
          headerCellStyle: multipleGroups
            ? { height: 100, marginTop: -50, whiteSpace: 'normal' }
            : {},
        });
      }
    });

    return {
      headerGroups: [
        headerGroups[0].map((column, index) => {
          return {
            ...DEFAULT_COL_DEF,
            ...column,
            left: calculateLeft(column, index, headerGroups[0]),
          };
        }),
        headerGroups[1].map((column, index) => {
          return {
            ...DEFAULT_COL_DEF,
            ...column,
            left: calculateLeft(column, index, headerGroups[1]),
          };
        }),
      ],
      columns: columns.map((column, index) => {
        return {
          ...DEFAULT_COL_DEF,
          ...column,
          left: calculateLeft(column, index, columns),
        };
      }),
    };
  } else {
    colDefs.forEach(column => {
      columns.push(column);
      headerGroups[0].push(column);
    });

    return {
      headerGroups: [
        headerGroups[0].map((column, index) => {
          return {
            ...DEFAULT_COL_DEF,
            ...column,
            left: calculateLeft(column, index, headerGroups[0]),
          };
        }),
      ],
      columns: columns.map((column, index) => {
        return {
          ...DEFAULT_COL_DEF,
          ...column,
          left: calculateLeft(column, index, columns),
        };
      }),
    };
  }
};

const checkGridRowIdIsValid = (id, row) => {
  if (id === null || id === undefined) {
    throw new Error(
      [
        'The data grid component requires all rows to have a unique id property.',
        'A row was provided without id in the rows prop:',
        JSON.stringify(row),
      ].join('\n'),
    );
  }
};

const getGridRowId = (row, index, getRowId) => {
  const id = getRowId ? getRowId(row, index) : row.id;
  checkGridRowIdIsValid(id, row);
  return id;
};

const getCellValue = (row, column) => {
  const params = {
    rowId: row.id,
    rowData: row.data,
    colDef: column,
    field: column.field,
  };
  const value = column.valueGetter ? column.valueGetter(params) : row.data[column.field];
  return typeof value === 'string' ? value.toLowerCase() : value;
};

// TODO: implement sort for dates, numbers, etc.
const sortRows = (rows, sort, columns) => {
  const column = columns.find(column => column.field === sort.field);
  if (sort.field && sort.order && sort.order !== SORT_ORDERS.none && column) {
    const sortedRows = [...rows];
    sortedRows.sort((rowA, rowB) => {
      switch (sort.order) {
        case SORT_ORDERS.asc:
          return getCellValue(rowA, column) > getCellValue(rowB, column) ? 1 : -1;
        case SORT_ORDERS.desc:
          return getCellValue(rowA, column) < getCellValue(rowB, column) ? 1 : -1;
        case SORT_ORDERS.none:
        default:
          return 0;
      }
    });
    return sortedRows;
  }
  return rows;
};

export const prepareRows = ({ rowDefs, getRowId, sort, columns, serverSideSort }) => {
  const rows = rowDefs.map((row, index) => {
    const id = getGridRowId(row, index, getRowId);
    return {
      id,
      data: row,
    };
  });

  if (serverSideSort) {
    return rows;
  }

  return sortRows(rows, sort, columns);
};

export const getSortOrder = (newSortField, currentSortField, currentSortOrder) => {
  if (newSortField !== currentSortField) {
    return SORT_ORDERS.asc;
  }

  switch (currentSortOrder) {
    case SORT_ORDERS.asc:
      return SORT_ORDERS.desc;
    case SORT_ORDERS.desc:
      return SORT_ORDERS.none;
    default:
      return SORT_ORDERS.asc;
  }
};
