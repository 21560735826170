import React from 'react';
import { SvgIcon } from '../elements';

const Add = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.984 13.714h-10.272v10.286h-3.424v-10.286h-10.272v-3.429h10.272v-10.286h3.424v10.286h10.272v3.429z"></path>
    </SvgIcon>
  );
};

export default Add;
