import React from 'react';
import { Link } from 'react-router-dom';

const LinkCellRender = ({ to, value, target, rel }) => (
  <Link to={to} target={target} rel={rel}>
    {value}
  </Link>
);

export default React.memo(LinkCellRender);
