import React from 'react';
import { SvgIcon } from '../elements';

const ChevronLeft = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.41 2.82l-2.82-2.82-12 12 12 12 2.82-2.82-9.16-9.18 9.16-9.18z"></path>
    </SvgIcon>
  );
};

export default ChevronLeft;
