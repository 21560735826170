import React from 'react';
import { SvgIcon } from '../elements';

const Scale = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.36.08c-.68.18-1.35.72-1.65 1.32l-.15.31H6.83c-2.61 0-2.74 0-2.94.1a1 1 0 0 0-.3.26c-.07.09-.9 2.1-1.85 4.5L0 10.9v.46a4.26 4.26 0 1 0 8.52-.4c-.03-.12-.71-1.85-1.51-3.85L5.55 3.46c0-.02.9-.03 2-.03h2.01l.15.3A2.9 2.9 0 0 0 11.13 5a33247.42 33247.42 0 0 1-.86 14.72l-1.78.03c-1.6.02-1.82.03-2.15.13-.66.2-1.05.57-1.16 1.12a9.7 9.7 0 0 0-.05 1.38c.02 1.2.03 1.24.39 1.5.15.12.17.12 6.47.12s6.32 0 6.47-.11c.08-.06.2-.18.26-.26.1-.14.11-.22.1-1.42 0-1.08 0-1.3-.08-1.46-.2-.44-.56-.72-1.14-.88a9.9 9.9 0 0 0-2.1-.12l-1.78-.02-.04-.44-.4-6.93c-.2-3.57-.4-6.69-.4-6.92L12.83 5l.31-.15c.4-.2.92-.71 1.12-1.11l.15-.31h2c1.1 0 2 .01 2 .03l-1.45 3.66c-.8 2-1.48 3.73-1.5 3.84-.09.3.02 1.1.22 1.65a4.28 4.28 0 0 0 8.3-1.51c-.03-.25-3.44-8.8-3.6-9.03a1 1 0 0 0-.3-.25c-.2-.1-.33-.1-2.94-.1h-2.73l-.15-.3a2.59 2.59 0 0 0-2.9-1.34ZM5.32 7.52 6.4 10.2c.03.09-.1.1-2.13.1s-2.15-.01-2.12-.1c.2-.54 2.1-5.27 2.12-5.27.02 0 .5 1.17 1.06 2.59Zm15.45 0 1.07 2.68c.03.09-.1.1-2.12.1-2.04 0-2.16-.01-2.13-.1.2-.54 2.1-5.27 2.13-5.27.01 0 .49 1.17 1.05 2.59Z"></path>
    </SvgIcon>
  );
};

export default Scale;
