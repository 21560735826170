import React from 'react';
import { SvgIcon } from '../elements';

const Analytics = props => {
  return (
    <SvgIcon {...props}>
      <path d="M3.563 11.25c0.567 0 1.081-0.228 1.46-0.593l2.081 1.041c-0.010 0.085-0.026 0.168-0.026 0.256 0 1.163 0.946 2.109 2.109 2.109s2.109-0.946 2.109-2.109c0-0.325-0.080-0.629-0.212-0.904l2.823-2.823c0.275 0.132 0.579 0.211 0.904 0.211 1.163 0 2.109-0.946 2.109-2.109 0-0.219-0.043-0.426-0.105-0.625l2.453-1.839c0.335 0.224 0.736 0.355 1.168 0.355 1.163 0 2.109-0.946 2.109-2.109s-0.946-2.109-2.109-2.109c-1.163 0-2.109 0.946-2.109 2.109 0 0.219 0.043 0.426 0.105 0.625l-2.453 1.839c-0.335-0.224-0.736-0.355-1.168-0.355-1.163 0-2.109 0.946-2.109 2.109 0 0.325 0.080 0.629 0.212 0.904l-2.824 2.823c-0.275-0.132-0.579-0.212-0.904-0.212-0.567 0-1.081 0.228-1.46 0.593l-2.081-1.041c0.010-0.085 0.026-0.168 0.026-0.256 0-1.163-0.946-2.109-2.109-2.109s-2.109 0.946-2.109 2.109c0 1.163 0.946 2.109 2.109 2.109z"></path>
      <path d="M23.297 22.594h-0.75v-14.859c0-0.389-0.315-0.703-0.703-0.703h-2.813c-0.389 0-0.703 0.315-0.703 0.703v14.859h-1.406v-10.641c0-0.389-0.315-0.703-0.703-0.703h-2.812c-0.389 0-0.703 0.315-0.703 0.703v10.641h-1.406v-5.016c0-0.389-0.315-0.703-0.703-0.703h-2.813c-0.389 0-0.703 0.315-0.703 0.703v5.016h-1.406v-7.828c0-0.389-0.315-0.703-0.703-0.703h-2.812c-0.389 0-0.703 0.315-0.703 0.703v7.828h-0.75c-0.389 0-0.703 0.315-0.703 0.703s0.315 0.703 0.703 0.703h22.594c0.389 0 0.703-0.315 0.703-0.703s-0.315-0.703-0.703-0.703z"></path>
    </SvgIcon>
  );
};

export default Analytics;
