const ORDER_STATUSES = [
  { label: 'orders:statuses.collecting', value: 1, background: '#65E3EB' },
  { label: 'orders:statuses.delivering', value: 2, background: '#F8E750' },
  { label: 'orders:statuses.delivered', value: 3, background: '#B4E44D' },
  { label: 'orders:statuses.canceled', value: 4, background: '#CACDD9' },
];

const ORDER_STATUSES_ENUM = [
  { label: 'orders:statuses.new', value: 'NEW', background: '#e8e8e8' },
  { label: 'orders:statuses.collecting', value: 'IN_PROCESS', background: '#f4fcc5' },
  { label: 'orders:statuses.collected', value: 'PROCESSED', background: '#65E3EB' },
  { label: 'orders:statuses.delivering', value: 'IN_DELIVERY', background: '#F8E750' },
  { label: 'orders:statuses.transferred', value: 'TRANSFERRED', background: '#98fab1' },
  { label: 'orders:statuses.delivered', value: 'DELIVERED', background: '#05fc44' },
  { label: 'orders:statuses.canceled', value: 'CANCELED', background: '#CACDD9' },
];

const PAYMENT_METHODS = [
  { label: 'orders:paymentMethods.cash', value: 1 },
  { label: 'orders:paymentMethods.cardOffline', value: 2 },
  { label: 'orders:paymentMethods.cardOnline', value: 3 },
  { label: 'orders:paymentMethods.applePay', value: 4 },
  { label: 'orders:paymentMethods.googlePay', value: 5 },
  { label: 'orders:paymentMethods.transferOnline', value: 6 },
  { label: 'orders:paymentMethods.oplati', value: 7 },
];

export { ORDER_STATUSES, ORDER_STATUSES_ENUM, PAYMENT_METHODS };
