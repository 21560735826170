import React from 'react';
import SelectControl from './SelectControl/SelectControl';
import { SelectMenu } from './SelectMenu';
import { SelectPopper } from './SelectPopper';
import useSelect from './useSelect';

/* TODO:
    - think about values syncing
    - split to two different components ?_?
*/
const Select = ({
  className,
  isSearchable,
  isMulti,
  isLoading,
  isInvalid,
  isBordered,
  isFilterable,
  disabled,
  containerStyles,
  size,
  placeholder,
  options,
  value,
  getOptionLabel,
  getOptionValue,
  getOptionClassName,
  onChange,
  onInputValueChange,
}) => {
  const {
    isMenuOpen,
    items,
    selectedItems,
    highlightedIndex,
    getRootProps,
    getPopperProps,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    getDropdownProps,
    openMenu,
    setInputValue,
    clearInputValue,
  } = useSelect({
    isSearchable,
    isMulti,
    isFilterable,
    options,
    value,
    getOptionLabel,
    getOptionValue,
    getOptionClassName,
    onChange,
    onInputValueChange,
  });

  return (
    <div {...getRootProps({ className, style: { position: 'relative', ...containerStyles } })}>
      <SelectControl
        isSearchable={isSearchable}
        isMulti={isMulti}
        isLoading={isLoading}
        isInvalid={isInvalid}
        isBordered={isBordered}
        isMenuOpen={isMenuOpen}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        selectedItems={selectedItems}
        getComboboxProps={getComboboxProps}
        getInputProps={getInputProps}
        getDropdownProps={getDropdownProps}
        getToggleButtonProps={getToggleButtonProps}
        openMenu={openMenu}
        setInputValue={setInputValue}
        clearInputValue={clearInputValue}
      />
      {/* fix ref downshift error */}
      {!isMenuOpen && <div {...getMenuProps()} />}
      <SelectPopper {...getPopperProps()}>
        <SelectMenu
          isMulti={isMulti}
          items={items}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
        />
      </SelectPopper>
    </div>
  );
};

Select.defaultProps = {
  className: null,
  isSearchable: false,
  isMulti: false,
  isLoading: false,
  isInvalid: false,
  isBordered: true,
  isFilterable: true,
  disabled: false,
  containerStyles: {
    flex: 1,
    width: 'auto',
    minWidth: 'auto',
  },
  size: 'normal',
  placeholder: 'Выберите',
  options: [],
  value: null,
  getOptionLabel: option => option.label,
  getOptionValue: option => option.value,
  getOptionClassName: () => null,
  onChange: () => {},
  onInputValueChange: () => {},
};

export default Select;
