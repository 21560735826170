import config from 'config';

export const rollbarConfig = {
  accessToken: '576ae8de528940059571372c4b96b7b2',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: '1.0.0',
  },
  environment: config.SERVER,
  enabled: config.NODE_ENV === 'production',
};
