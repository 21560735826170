import React from 'react';
import { SvgIcon } from '../elements';

const Swap = props => {
  return (
    <SvgIcon {...props}>
      <path d="M18.109 1.091v19.184l3.353-3.592c0.398-0.426 1.042-0.426 1.44 0s0.398 1.117 0 1.543l-5.091 5.454c-0.398 0.426-1.042 0.426-1.44 0l-5.091-5.454c-0.199-0.213-0.298-0.492-0.298-0.771s0.099-0.558 0.298-0.771c0.398-0.426 1.042-0.426 1.44 0l3.353 3.592v-19.184c0-0.602 0.456-1.091 1.018-1.091s1.018 0.488 1.018 1.091v0zM12.72 5.774c0.398 0.426 0.398 1.117 0 1.543s-1.042 0.426-1.44 0l-3.353-3.592v19.184c0 0.603-0.456 1.091-1.018 1.091s-1.018-0.488-1.018-1.091v-19.184l-3.353 3.592c-0.398 0.426-1.042 0.426-1.44 0-0.199-0.213-0.298-0.492-0.298-0.771s0.099-0.558 0.298-0.771l5.091-5.454c0.398-0.426 1.042-0.426 1.44 0l5.091 5.454z"></path>
    </SvgIcon>
  );
};

export default Swap;
