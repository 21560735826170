import React from 'react';
import { useQuery } from 'react-query';
import { Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

const CouriersAutocomplete = ({ isBordered, size, value, onChange }) => {
  const { darkstoreId } = useSettingsContext();

  const { isLoading, data = [] } = useQuery(['autocompletes/couriers', { darkstoreId }], () => {
    return api
      .post('admin/employees/list', {
        darkstoreId,
        role: 'courier',
        page: 1,
        pageSize: 1000,
      })
      .then(data => data.rows);
  });

  return (
    <Select
      isSearchable
      isLoading={isLoading}
      isBordered={isBordered}
      size={size}
      options={data}
      value={value}
      getOptionLabel={option => `${option.surname} ${option.name}`}
      getOptionValue={option => option.id}
      onChange={onChange}
    />
  );
};

export default CouriersAutocomplete;
