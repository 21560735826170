import React from 'react';
import { SvgIcon } from '../elements';

const Link = props => {
  return (
    <SvgIcon {...props}>
      <path d="M22.353 1.647c-2.197-2.197-5.758-2.197-7.955-0l-4.701 4.701c-2.272 2.272-2.062 5.893 0 7.955 0.345 0.345 0.727 0.627 1.131 0.858l0.858-0.858c0.563-0.563 0.365-1.221 0.355-1.696-0.124-0.088-0.244-0.182-0.355-0.293-1.058-1.058-1.106-2.872 0-3.977 0.164-0.164 4.602-4.602 4.701-4.701 1.097-1.097 2.881-1.097 3.977 0s1.097 2.881 0 3.977l-3.107 3.107c0.090 0.497 0.629 1.675 0.352 3.618 0.014-0.013 0.030-0.022 0.043-0.035l4.701-4.701c2.197-2.197 2.197-5.758 0-7.955z"></path>
      <path d="M14.668 9.332c-0.345-0.345-0.727-0.627-1.131-0.858l-0.858 0.858c-0.563 0.563-0.365 1.221-0.355 1.696 0.124 0.088 0.244 0.182 0.355 0.293 1.058 1.058 1.106 2.872 0 3.977-0.165 0.165-4.969 4.969-5.066 5.066-1.097 1.097-2.881 1.097-3.977 0s-1.097-2.881 0-3.978l3.472-3.472c-0.090-0.497-0.629-1.675-0.352-3.618-0.014 0.013-0.030 0.022-0.043 0.035l-5.066 5.066c-2.197 2.197-2.197 5.758 0 7.955s5.758 2.197 7.955 0l5.066-5.066c2.23-2.23 2.108-5.847 0-7.955z"></path>
    </SvgIcon>
  );
};

export default Link;
