const STATUSES = [
  { label: 'Штатный режим', value: 'default' },
  { label: 'Временно не работаем', value: 'not-working' },
];

const DEMANDS = [
  { label: 'Стандартный', value: 'default' },
  { label: 'Повышенный', value: 'increased' },
  { label: 'Высокий', value: 'high' },
  { label: 'Высокий', value: 'high' },
  { label: 'Очень высокий', value: 'very-high' },
  { label: 'Очень высокий', value: 'very-high' },
  { label: 'Экстремально высокий', value: 'extreme-high' },
];

const DEFAULT_DELIVERY_TIMES = [30].map(value => ({ label: value.toString(), value }));

const DELIVERY_TIMES = { 30: [30, 45, 60, 75, 90, 105, 120] };

export { STATUSES, DEMANDS, DEFAULT_DELIVERY_TIMES, DELIVERY_TIMES };
