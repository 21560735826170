import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebouncedValue } from 'hooks';
import { Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

// TODO: use barcode also for search (need api updates)
const ProductsAutocomplete = ({
  isInvalid,
  placeholder = 'Введите штрихкод или название товара',
  value,
  onChange,
}) => {
  const { darkstoreId } = useSettingsContext();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);

  const { isLoading, data = [] } = useQuery(
    ['autocompletes/products', { darkstoreId, debouncedQuery }],
    () => api.get(`products/search/${debouncedQuery}/${darkstoreId}`),
    { enabled: !!debouncedQuery, cacheTime: 0 },
  );

  return (
    <Select
      isSearchable
      isInvalid={isInvalid}
      isFilterable={false}
      placeholder={placeholder}
      isLoading={isLoading}
      options={data}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      value={value}
      onChange={onChange}
      onInputValueChange={setQuery}
    />
  );
};

export default ProductsAutocomplete;
