import useSetState from './useSetState';

const useModalState = initialState => {
  const [state, setState] = useSetState({
    isOpened: false,
    ...initialState,
  });

  const open = () => setState({ isOpened: true });

  const close = () => setState({ isOpened: false });

  return {
    ...state,
    open,
    close,
    set: setState,
  };
};

export default useModalState;
