import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Search as SearchIcon, Close as CloseIcon } from 'components/icons';
import debounce from 'utils/debounce';
import styles from './SearchInput.module.scss';

const SearchInput = ({
  withDebounce = false,
  className = null,
  disabled,
  placeholder = 'Поиск',
  value = '',
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const debouncedOnChange = useMemo(() => debounce(onChange, 750), [onChange]);

  useEffect(() => setInputValue(value), [value]);

  const handleChange = e => {
    const {
      target: { value = '' },
    } = e;
    setInputValue(value);
    if (withDebounce) {
      debouncedOnChange(value);
    } else {
      onChange(value);
    }
  };

  const handleClear = () => {
    setInputValue('');
    onChange('');
  };

  return (
    <div className={clsx(styles.root, { [className]: className })}>
      <SearchIcon size={18} />
      <input
        disabled={disabled}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
      />
      <CloseIcon size={14} onClick={handleClear} />
    </div>
  );
};

export default SearchInput;
