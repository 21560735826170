import React from 'react';
import { SvgIcon } from '../elements';

const Eye = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.598 10.995C23.386 10.709 18.331 4 11.875 4 5.418 4 .363 10.71.15 10.995a.762.762 0 0 0 0 .91c.212.285 5.267 6.994 11.724 6.994 6.456 0 11.511-6.709 11.723-6.994a.76.76 0 0 0 0-.91Zm-11.723 6.363c-4.756 0-8.875-4.453-10.095-5.909 1.218-1.457 5.33-5.908 10.095-5.908 4.755 0 8.874 4.453 10.094 5.91-1.218 1.456-5.328 5.907-10.094 5.907Z" />
      <path d="M11.875 6.823c-2.59 0-4.698 2.074-4.698 4.624s2.108 4.624 4.698 4.624c2.59 0 4.698-2.075 4.698-4.624 0-2.55-2.108-4.624-4.698-4.624Zm0 7.706c-1.727 0-3.132-1.382-3.132-3.082s1.405-3.083 3.132-3.083 3.132 1.383 3.132 3.083c0 1.7-1.405 3.082-3.132 3.082Z" />
    </SvgIcon>
  );
};

export default Eye;
