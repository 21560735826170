import React from 'react';
import { SvgIcon } from '../elements';

const ArrowDown = props => {
  return (
    <SvgIcon {...props}>
      <path d="M24 12l-2.115-2.115-8.385 8.37v-18.255h-3v18.255l-8.37-8.385-2.13 2.13 12 12 12-12z"></path>
    </SvgIcon>
  );
};

export default ArrowDown;
