import React from 'react';
import { Button, Col, Form, Row, Spinner, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'hooks';
import { UpDownButton } from 'components/elements';
import { Close as CloseIcon } from 'components/icons';
import { round } from 'utils/helpers';
import message from 'utils/message';
import recalculateProduct from '../recalculateProduct';
import { ProviderProductsAutocomplete } from './ProviderProductsAutocomplete';
import useAddProduct from './useAddProduct';

const INITIAL_STATE = { product: null, qty: 1 };

const ProductAddModal = ({ supplyId, providerId, addedProductIds, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [state, setState] = useSetState(INITIAL_STATE);

  const { isAdding, addProduct } = useAddProduct({ onSuccess });

  return (
    <Modal show centered size="md" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{t('supply:productAddModal.title')}</Modal.Title>
        <CloseIcon size={20} onClick={onClose} />
      </Modal.Header>
      {isAdding ? (
        <Modal.Body>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (addedProductIds.includes(state.product.productId)) {
              message('Этот товар уже добавлен в поставку', { type: 'error' });
            } else {
              if (supplyId) {
                addProduct({ supplyId, productId: state.product.productId, qty: state.qty });
              } else {
                onSuccess(
                  recalculateProduct({
                    ...state.product,
                    priceWithoutVat: round(state.product.pricePurchases),
                    qty: Number(state.qty),
                  }),
                );
              }
            }
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t('supply:productAddModal.product')}</Form.Label>
              <ProviderProductsAutocomplete
                placeholder={t('supply:productAddModal.productPlaceholder')}
                providerId={providerId}
                value={state.product}
                onChange={product => setState({ product, qty: 1 })}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>{t('supply:productAddModal.amount')}</Form.Label>
              <Col xs="auto">
                <UpDownButton value={state.qty} onChange={qty => setState({ qty })} />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              {t('shared:buttons.cancel')}
            </Button>
            <Button type="submit" disabled={!state.product}>
              {t('shared:buttons.add')}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default ProductAddModal;
