const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_FILESTORAGE_URL,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_SERVER,
} = process.env;

const config = {
  NODE_ENV: NODE_ENV || 'development',
  IS_DEVELOPMENT_ENV: process.env.NODE_ENV === 'development',
  API_URL: REACT_APP_API_URL || 'https://api.bazar-store.by',
  FILESTORAGE_URL: REACT_APP_FILESTORAGE_URL || 'https://bazar-store.by',
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY || 'AIzaSyD8uEijT8Aryue-HNrGdgQxQfnTrVlEGvE',
  SERVER: REACT_APP_SERVER || 'test',
};

export default config;
