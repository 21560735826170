import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import styles from './SelectPopper.module.scss';

const SelectPopper = ({ children, show, target, style }) => {
  return (
    <Overlay
      flip
      show={show}
      target={target}
      placement="bottom-start"
      popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
    >
      <Popover className={styles.root} style={style}>
        {children}
      </Popover>
    </Overlay>
  );
};

export default SelectPopper;
