import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Logout as LogoutIcon } from 'components/icons';
import { useAuthContext } from 'contexts/AuthContext';
import logo from 'img/logo.svg';
import logoLogin from 'img/logoLogin.svg';
import { Darkstores } from '../Darkstores';
import styles from './Sidebar.module.scss';
import useSidebarLinks from './useSidebarLinks';

const Sidebar = ({ isOpened, withDarkstoreSelect, setIsOpened }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { logout } = useAuthContext();

  const links = useSidebarLinks();

  return (
    <aside className={clsx(styles.root, isOpened && styles.rootOpened)}>
      <img className={styles.logo} src={isOpened ? logo : logoLogin} alt="Bazar" />

      {withDarkstoreSelect && (
        <div className={styles.selectWrapper}>
          <Darkstores isMinimized={!isOpened} />
        </div>
      )}

      <nav>
        {links.map((section, index) => {
          return (
            <Fragment key={index}>
              {section.map(link => {
                const { icon: DrawerItemIcon, path, key, desktopOnly } = link;
                const active = pathname.includes(path);

                return (
                  <Fragment key={path}>
                    <Link
                      className={clsx(
                        styles.item,
                        active && styles.itemActive,
                        desktopOnly && styles.itemDesktopOnly,
                      )}
                      to={path}
                      onClick={() => {
                        if (window.matchMedia('only screen and (max-width: 768px)').matches) {
                          setIsOpened(false);
                        }
                      }}
                    >
                      <span className={styles.itemMarker} />
                      <DrawerItemIcon className={styles.itemIcon} size={20} />
                      {isOpened && t(`layout:drawer.${key}`)}
                    </Link>
                    {path === '/supplies' && (
                      <ul className={clsx(styles.itemOptions, styles.itemDesktopOnly)}>
                        <li
                          className={clsx(
                            pathname.includes('/supplies/new-request') && styles.active,
                          )}
                        >
                          <Link to="/supplies/new-request">{t('layout:drawer.new-request')}</Link>
                        </li>
                      </ul>
                    )}
                  </Fragment>
                );
              })}
              {index < links.length - 1 && <div className={styles.divider} />}
            </Fragment>
          );
        })}

        <div className={styles.item} onClick={() => logout()}>
          <LogoutIcon className={styles.itemIcon} size={20} />
          {isOpened && t('layout:drawer.exit')}
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
