import React from 'react';
import { SvgIcon } from '../elements';

const Search = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.706 22.295l-5.77-5.77c1.43-1.744 2.292-3.977 2.292-6.411 0-5.584-4.531-10.114-10.114-10.114-5.589 0-10.114 4.531-10.114 10.114s4.526 10.114 10.114 10.114c2.434 0 4.663-0.857 6.407-2.287l5.77 5.765c0.392 0.392 1.024 0.392 1.415 0 0.392-0.387 0.392-1.024 0-1.411zM10.114 18.215c-4.472 0-8.106-3.634-8.106-8.101s3.634-8.106 8.106-8.106c4.467 0 8.106 3.639 8.106 8.106s-3.639 8.101-8.106 8.101z"></path>
    </SvgIcon>
  );
};

export default Search;
