import React from 'react';
import { SvgIcon } from '../elements';

const StarOutline = props => {
  return (
    <SvgIcon {...props}>
      <path d="M24 9.288l-8.628-0.744-3.372-7.944-3.372 7.956-8.628 0.732 6.552 5.676-1.968 8.436 7.416-4.476 7.416 4.476-1.956-8.436 6.54-5.676zM12 16.68l-4.512 2.724 1.2-5.136-3.984-3.456 5.256-0.456 2.040-4.836 2.052 4.848 5.256 0.456-3.984 3.456 1.2 5.136-4.524-2.736z"></path>
    </SvgIcon>
  );
};

export default StarOutline;
