import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

const format = input => {
  let str = input;
  if (str.length > 0) {
    str = str.replace(/\D/g, '').slice(0, 4);
    let arr = str.split('').map(sign => Number(sign));
    if (arr.length > 0) {
      if (arr[0] > 2) {
        arr[0] = 2;
      }

      if (arr.length > 1) {
        if (arr[0] === 2 && arr[1] > 3) {
          arr[1] = 3;
        }
      }

      // fix input with ":"
      if (arr.length === 2 && input.includes(':')) {
        return `${arr.join('')}:`;
      }

      if (arr.length > 2) {
        if (arr[2] > 5) {
          arr[2] = 5;
        }
      }

      str = arr.join('');
      if (str.length > 2) {
        str = `${str.slice(0, 2)}:${str.slice(2)}`;
      }
    }
  }
  return str;
};

const normalize = input => {
  let str = input;
  if (str.length > 0) {
    str = str.replace(/\D/g, '').slice(0, 4);
    if (str.length < 4) {
      str = (str + '0000').slice(0, 4);
    }
    str = `${str.slice(0, 2)}:${str.slice(2)}`;
  }
  return str;
};

const TimeInput = ({ className, disabled, value, onChange, onBlur }) => {
  const [innerValue, setInnerValue] = useState(value);
  const innerValueRef = useRef(innerValue);

  useEffect(() => {
    innerValueRef.current = innerValue;
  }, [innerValue]);

  useEffect(() => {
    if (value !== innerValueRef.current) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = e => {
    const formattedValue = format(e.target.value);
    setInnerValue(formattedValue);
    if (formattedValue.length === 5 && onChange) {
      onChange(formattedValue);
    }
  };

  const handleBlur = e => {
    if (e.target.value.length < 5) {
      const normalizedValue = normalize(e.target.value);
      setInnerValue(normalizedValue);
      if (onChange) {
        onChange(normalizedValue);
      }
    }
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <Form.Control
      className={className}
      disabled={disabled}
      type="text"
      placeholder="HH:MM"
      value={innerValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default TimeInput;
