import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { useSettingsContext } from 'contexts/SettingsContext';
import pl from './pl.json';
import uk from './uk.json';

const PREFERRED_COUNTRIES = ['by', 'ru', 'ua', 'kz', 'pl'];

const MASKS_BY_COUNTRY = {
  by: '375 (29) 123-45-67',
  ru: '7 (910) 123-45-67',
  ua: '380 (96) 123-45-67',
  kz: '7 (700) 123-45-67',
  pl: '48 (77) 123-45-67',
};

const LOCALIZATIONS_BY_LANGUAGE = {
  ru,
  uk,
  pl,
};

const useLocalization = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return LOCALIZATIONS_BY_LANGUAGE[language];
};

const PhoneTextField = ({ isInvalid, value, onChange, ...rest }) => {
  const localization = useLocalization();

  const { partnerId } = useSettingsContext();

  const [countryCode, setCountryCode] = useState(PREFERRED_COUNTRIES[partnerId]);

  return (
    <ReactPhoneInput
      localization={localization}
      country={countryCode}
      preferredCountries={PREFERRED_COUNTRIES}
      inputClass={isInvalid && 'is-invalid'}
      buttonClass={isInvalid && 'is-invalid'}
      placeholder={MASKS_BY_COUNTRY[countryCode]}
      value={value}
      onChange={(newValue, country) => {
        setCountryCode(country.countryCode);
        onChange(newValue);
      }}
      {...rest}
    />
  );
};

export default PhoneTextField;
