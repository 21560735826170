export const getVirtualized = ({ height, scrollTop, rows, rowHeight }) => {
  let virtualizedRows = [];
  let beginHeight = 0;
  let endHeight = 0;

  rows.reduce((acc, row) => {
    if (acc >= scrollTop - rowHeight) {
      if (height >= -(rowHeight * 5)) {
        height = height - rowHeight;
        virtualizedRows.push(row);
      } else {
        endHeight += rowHeight;
      }
    } else {
      beginHeight = acc + rowHeight;
    }
    return acc + rowHeight;
  }, 0);

  return { beginHeight, endHeight, virtualizedRows };
};
