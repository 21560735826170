import React from 'react';
import { Card } from 'react-bootstrap';
import clsx from 'clsx';
import noAvatar from 'img/noAvatar.png';
import styles from './Review.module.scss';

const Review = ({ className, avatar, author, date, text }) => {
  return (
    <Card className={clsx(styles.root, className)}>
      <Card.Body className={styles.content}>
        <img className={styles.avatar} src={avatar || noAvatar} alt="avatar" />
        <div className={styles.info}>
          <span className={styles.author}>{author}</span>
          <span className={styles.date}>{date}</span>
          <p className={styles.text}>{text}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Review;
