import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipCellRender = ({ children, formattedValue, value }) => {
  if (!children && !formattedValue && !value) {
    return '-';
  }

  return (
    <OverlayTrigger overlay={<Tooltip>{children || formattedValue || value}</Tooltip>}>
      <span>{children || formattedValue || value}</span>
    </OverlayTrigger>
  );
};

export default TooltipCellRender;
