import React from 'react';
import clsx from 'clsx';
import { GridCell } from '../GridCell';
import { GridRowMenu } from '../GridRowMenu';
import styles from './GridRow.module.scss';

const GridRow = ({
  id,
  data,
  columns,
  height,
  menu,
  updateCell,
  editedCell,
  startCellEditing,
  cancelCellEditing,
  finishCellEditing,
  getRowClassName,
  onCellClick,
}) => {
  const className =
    getRowClassName &&
    getRowClassName({
      rowId: id,
      rowData: data,
    });

  return (
    <tr className={clsx(styles.root, className)} style={{ height }}>
      {columns.map(column => {
        const params = {
          rowId: id,
          rowData: data,
          colDef: column,
          field: column.field,
        };

        const value = column.valueGetter ? column.valueGetter(params) : data[column.field];

        const formattedValue = column.valueFormatter
          ? column.valueFormatter({ ...params, value })
          : value;

        let cellRender = null;
        if (editedCell && editedCell.rowId === id && editedCell.field === column.field) {
          cellRender = column.renderEditCell({
            ...params,
            value,
            formattedValue,
            cancelCellEditing,
            finishCellEditing,
          });
        } else if (column.renderCell) {
          cellRender = column.renderCell({
            ...params,
            value,
            formattedValue,
            updateCell,
          });
        }

        const onClick = column.isEditable
          ? () => {
              // fix cell re-editing
              if (!editedCell || (editedCell.rowId !== id && editedCell.field !== column.field)) {
                onCellClick && onCellClick({ ...params, value, formattedValue });
                startCellEditing({ rowId: id, field: column.field });
              }
            }
          : () => onCellClick && onCellClick({ ...params, value, formattedValue });

        return (
          <GridCell
            key={column.field}
            style={column.cellStyle}
            width={column.width}
            flex={column.flex}
            left={column.left}
            isSticky={column.isSticky}
            isEditable={column.isEditable}
            onClick={onClick}
          >
            {cellRender || formattedValue || value || '-'}
          </GridCell>
        );
      })}

      {menu && <GridRowMenu rowId={id} rowData={data} items={menu} />}
    </tr>
  );
};

export default React.memo(GridRow);
