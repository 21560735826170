import Axios from 'axios';
import config from 'config';

const AxiosInstance = Axios.create({ baseURL: config.API_URL });

const createStringParams = params => {
  if (!params) {
    return '';
  }

  if (Object.keys(params).length === 0) {
    return '';
  }

  const stringParams = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return `?${stringParams}`;
};

const createConfig = responseType => {
  const token = localStorage.getItem('token');
  const language = localStorage.getItem('language');
  return {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: token,
      language: language || 'ru',
    },
    responseType,
  };
};

const throwError = exception => {
  if (exception.response) {
    const {
      response: {
        status,
        data: { error, message },
      },
    } = exception;

    let err = { code: status, response: true };

    const token = localStorage.getItem('token');
    if (token && status === 401) {
      localStorage.removeItem('token');
      window.location.pathname = '/login';
    }

    if (status === 404) {
      err.message = 'Запрашиваемый элемент не найден';
    } else {
      err.message = error || message || 'Неизвестная ошибка';
    }

    throw err;
  }

  const { error, message } = exception;

  const err = { response: false, message: error || message || 'Сервер недоступен' };

  throw err;
};

const api = {
  useInterceptor: fn => AxiosInstance.interceptors.request.use(fn),
  ejectInterceptor: fn => AxiosInstance.interceptors.request.eject(fn),
  get: async (url, params, responseType) => {
    try {
      const { data } = await AxiosInstance.get(
        `${url}${createStringParams(params)}`,
        createConfig(responseType),
      );
      return data;
    } catch (e) {
      return throwError(e);
    }
  },
  post: async (url, body) => {
    try {
      const { data } = await AxiosInstance.post(url, body, createConfig());
      return data;
    } catch (e) {
      return throwError(e);
    }
  },
  put: async (url, body) => {
    try {
      const { data } = await AxiosInstance.put(url, body, createConfig());
      return data;
    } catch (e) {
      return throwError(e);
    }
  },
  patch: async (url, body) => {
    try {
      const { data } = await AxiosInstance.patch(url, body, createConfig());
      return data;
    } catch (e) {
      return throwError(e);
    }
  },
  delete: async (url, params) => {
    try {
      const { data } = await AxiosInstance.delete(
        `${url}${createStringParams(params)}`,
        createConfig(),
      );
      return data;
    } catch (e) {
      return throwError(e);
    }
  },
};

export default api;
