import React from 'react';
import config from 'config';

const AvatarCellRender = ({ value }) => (
  <img
    src={`${config.FILESTORAGE_URL}/${value}`}
    alt="avatar"
    style={{ width: '26px', height: '26px', borderRadius: '13px' }}
  />
);

export default React.memo(
  AvatarCellRender,
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);
