const debounce = (callback, delay = 500) => {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => callback(...args), delay);
  };
};

export default debounce;
