const { useMemo } = require('react');

const LANGUAGE_RU = { key: 'ru', label: 'Русский' };
const LANGUAGE_UK = { key: 'uk', label: 'Украинский' };
const LANGUAGE_PL = { key: 'pl', label: 'Польский' };

const useLanguages = ({ partnerId }) => {
  return useMemo(() => {
    switch (partnerId) {
      case 2:
        return [LANGUAGE_RU, LANGUAGE_UK];
      case 4:
        return [LANGUAGE_PL];
      default:
        return [LANGUAGE_RU];
    }
  }, [partnerId]);
};

export default useLanguages;
