import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { DatePicker } from 'components/elements';
import api from 'utils/api';
import { formatDate } from 'utils/formatters';
import message from 'utils/message';

const yesterday = dayjs().subtract(1, 'days').toDate();

const WriteOffActDownload = ({ darkstoreId }) => {
  const { t } = useTranslation('writeOffs', { keyPrefix: 'actDownload' });

  const [date, setDate] = useState(yesterday);

  const { isLoading: isDownloading, refetch: downloadAct } = useQuery(
    'writeOffActDownload',
    async () => {
      const data = await api.get(
        `/admin/reports/writeOff?darkstoreId=${darkstoreId}&date=${formatDate(date, 'YYYY-MM-DD')}`,
      );

      if (!data.url) {
        throw new Error(t('error'));
      }

      const file = await api.get(data.url, null, 'blob');

      if (data) {
        saveAs(file, data.url.split('/').pop());
      }

      return null;
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: false,
      onError: error => message(error.message, { type: 'error ' }),
    },
  );

  return (
    <div className="d-flex">
      <DatePicker className="mr-2" maxDate={yesterday} value={date} onChange={setDate} />
      <Button variant="primary" onClick={downloadAct}>
        {isDownloading && (
          <Spinner as="span" animation="border" size="sm" role="status" variant="light" />
        )}
        {t('label')}
      </Button>
    </div>
  );
};

export default WriteOffActDownload;
