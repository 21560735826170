import React from 'react';
import { Menu } from '../../Menu';
import styles from './GridRowMenu.module.scss';

const GridRowMenu = ({ rowData, items }) => {
  return (
    <td className={styles.root}>
      <Menu
        items={items.map(item => ({
          ...item,
          onClick: () => item.onClick && item.onClick(rowData),
        }))}
      />
    </td>
  );
};

export default GridRowMenu;
