import React from 'react';
import { SvgIcon } from '../elements';

const Copy = props => {
  return (
    <SvgIcon {...props}>
      <path d="M14.99 4.157c0.001-0 0.002-0.001 0.002-0.002s0.002-0.003 0.004-0.002l4.381 1.962c0.060 0.027 0.129 0.029 0.191 0.006l3.771-1.414c0.218-0.082 0.218-0.39 0-0.472l-11.25-4.219c-0.057-0.021-0.12-0.021-0.177 0l-11.25 4.219c-0.218 0.082-0.218 0.39 0 0.472l11.25 4.219c0.057 0.021 0.12 0.021 0.177-0l3.791-1.422c0.214-0.080 0.219-0.382 0.008-0.469l-3.885-1.601c-0.001-0.001-0.002-0.002-0.002-0.003s0.001-0.003 0.002-0.003l2.988-1.27zM0.372 6.567c-0.165-0.062-0.34 0.060-0.34 0.236v12.926c0 0.105 0.065 0.199 0.163 0.236l10.716 4.019c0.165 0.062 0.34-0.060 0.34-0.236v-12.926c0-0.105-0.065-0.199-0.163-0.236l-10.716-4.019zM6.016 19.589c0 0.176-0.176 0.298-0.34 0.236l-2.488-0.933c-0.098-0.037-0.163-0.131-0.163-0.236v-1.058c0-0.176 0.176-0.298 0.34-0.236l2.488 0.933c0.098 0.037 0.163 0.131 0.163 0.236v1.058zM19.644 8.061c-0.098 0.037-0.164 0.131-0.164 0.236v3.398c0 0.105-0.065 0.199-0.164 0.236l-2.488 0.933c-0.165 0.062-0.34-0.060-0.34-0.236v-3.020c0-0.176-0.176-0.298-0.341-0.236l-3.236 1.214c-0.098 0.037-0.163 0.131-0.163 0.236v12.926c0 0.176 0.176 0.298 0.34 0.236l10.716-4.019c0.098-0.037 0.164-0.131 0.164-0.236v-12.926c0-0.176-0.176-0.298-0.34-0.236l-3.984 1.494z"></path>
    </SvgIcon>
  );
};

export default Copy;
