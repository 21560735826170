import { useMemo } from 'react';
import { LinkCellRender } from 'components/cell-renders';

const TRANSLATION_PREFIX = `supply:columns`;

const makeColumns = ({ t, status, currency }) => [
  {
    header: t(`${TRANSLATION_PREFIX}.barcode`),
    field: 'cod',
    width: 175,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.name`),
    field: 'name',
    width: 350,
    renderCell: ({ value, rowData }) => (
      <LinkCellRender to={`/assortiment/products/${rowData.productId}`} value={value} />
    ),
  },
  {
    header: t(`${TRANSLATION_PREFIX}.weight`),
    field: 'weight',
    valueFormatter: ({ value, rowData: { liter } }) => `${value} ${liter === 1 ? 'л' : 'кг'}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.qty`),
    field: 'qty',
    type: 'number',
    isEditable: status !== 2,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.priceWithoutVat`),
    field: 'priceWithoutVat',
    width: 300,
    type: 'number',
    isEditable: true,
    valueFormatter: ({ value }) => `${value} ${currency}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.sumWithoutVat`),
    field: 'sumWithoutVat',
    width: 250,
    valueFormatter: ({ value }) => `${value} ${currency}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.vat`),
    field: 'vat',
    valueFormatter: ({ value }) => `${value} %`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.vatValue`),
    field: 'vatValue',
    valueFormatter: ({ value }) => `${value} ${currency}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.vatSum`),
    field: 'vatSum',
    valueFormatter: ({ value }) => `${value} ${currency}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.extraCharge`),
    field: 'extraCharge',
    valueFormatter: ({ value }) => `${value} %`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.salePrice`),
    field: 'price',
    width: 200,
    valueFormatter: ({ value }) => `${value} ${currency}`,
  },
  {
    header: t(`${TRANSLATION_PREFIX}.sku`),
    field: 'sku',
    width: 200,
  },
];

const useColumns = ({ t, status, currency }) => {
  const columns = useMemo(() => makeColumns({ t, status, currency }), [t, status, currency]);

  return columns;
};

export default useColumns;
