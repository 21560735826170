import React, { useState } from 'react';
import { formatDate } from 'utils/formatters';
import NewRequestContext from './NewRequestContext';

const INITIAL_STATE = {
  darkstores: [],
  providers: [],
  dateFrom: formatDate(new Date(), 'YYYY-MM-DD'),
  dateTo: formatDate(new Date(), 'YYYY-MM-DD'),
  remain: null,
  dates: [null],
  isOnlyAvailable: false,
};

const NewRequestProvider = ({ children }) => {
  const [filters, setFilters] = useState(INITIAL_STATE);

  const resetFilters = () => setFilters(INITIAL_STATE);

  return (
    <NewRequestContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
      }}
    >
      {children}
    </NewRequestContext.Provider>
  );
};

export default NewRequestProvider;
