import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from 'hooks';
import errorIcon from 'img/errorMessageIcon.svg';
import successIcon from 'img/successMessageIcon.svg';

const message = (text = '', options = {}) =>
  toast(
    <>
      {options.type === 'success' && options.withIcon && <img src={successIcon} alt="success" />}
      {options.type === 'error' && options.withIcon && <img src={errorIcon} alt="error" />}
      {text}
    </>,
    options,
  );

export const MessagesOutlet = () => {
  const isMaxWidth640 = useMediaQuery('screen and (max-width: 640px)');

  return (
    <ToastContainer
      limit={isMaxWidth640 ? 1 : null}
      autoClose={isMaxWidth640 ? 1500 : 3000}
      position={isMaxWidth640 ? 'top-center' : 'bottom-left'}
      hideProgressBar
      closeButton={false}
      pauseOnHover={false}
    />
  );
};

export default message;
