import React from 'react';
import PropTypes from 'prop-types';
import { GridAutoSizer } from './GridAutoSizer';
import { GridBody } from './GridBody';
import { GridContent } from './GridContent';
import { GridHeader } from './GridHeader';
import { GridLoadingOverlay } from './GridLoadingOverlay';
import { GridNoDataOverlay } from './GridNoDataOverlay';
import { GridPagination } from './GridPagination';
import { GridRoot } from './GridRoot';
import { GridToolbar } from './GridToolbar';
import { GridWindow } from './GridWindow';
import useGridState from './useGridState';

/* TODO:

### Phase 1

* [x] Pagination
* [x] Header groups
* [x] Row menu
* [x] Icons (menu, sort)
* [x] Loading and NoData overlays
* [x] Toolbar (title, reload, pagesize)
* [x] Infinite scroll
* [x] Save sort to local storage
* [x] Tooltip (!resolved by separate cell render)
* [x] Clickable cells
* [ ] Refactor headerGroups & rows (with cells) preparing (cellStyle, width, flex, left -> style, sort use valueGetter)
* [ ] Add column types (string, number, date, datetime, currency (price), percentage)
* [ ] Add cellEditRenders for different column types
* [ ] Add more cell|row|grid events
* [ ] Add GridContext (GridApi) to provide all props (data, methods) throw context (like material-ui data-grid) (???)

*/

const DataGrid = ({
  id,
  className,
  isLoading,
  isVirtualized,
  columns: colDefs,
  rows: rowDefs,
  height: rootHeight,
  rowHeight,
  title,
  initialSort,
  initialPageSize,
  pageSizeOptions,
  pagesCount,
  rowsCount,
  rowMenu,
  getRowId,
  getRowClassName,
  onCellClick,
  onCellChange,
  onCellEdited,
  onSortChange,
  onPageChange,
  onPageSizeChange,
  onReload,
  onLoadMore,
}) => {
  const {
    scrollRef,
    headerGroups,
    columns,
    rows,
    sort,
    page,
    pageSize,
    editedCell,
    updateCell,
    startCellEditing,
    cancelCellEditing,
    finishCellEditing,
    changeSort,
    changePage,
    changePageSize,
  } = useGridState({
    id,
    colDefs,
    rowDefs,
    initialSort,
    initialPageSize,
    getRowId,
    onCellChange,
    onCellEdited,
    onSortChange,
    onPageChange,
    onPageSizeChange,
  });

  return (
    <GridRoot className={className} height={rootHeight}>
      {isLoading && <GridLoadingOverlay />}

      {!isLoading && rows.length === 0 && <GridNoDataOverlay />}

      <GridToolbar
        scrollRef={scrollRef}
        title={title}
        pageSizeOptions={pageSizeOptions}
        rowsCount={rowsCount}
        pagesCount={pagesCount}
        page={page}
        pageSize={pageSize}
        onPageChange={changePage}
        onPageSizeChange={changePageSize}
        onReload={onReload}
        onLoadMore={onLoadMore}
      />

      <GridAutoSizer>
        {({ width, height }) => (
          <GridWindow
            scrollRef={scrollRef}
            width={width}
            height={height}
            isLoading={isLoading}
            isVirtualized={isVirtualized}
            rows={rows}
            rowHeight={rowHeight}
            onLoadMore={onLoadMore}
          >
            {scrollTop => (
              <GridContent>
                <GridHeader headerGroups={headerGroups} sort={sort} changeSort={changeSort} />

                <GridBody
                  height={height}
                  scrollTop={scrollTop}
                  isVirtualized={isVirtualized}
                  columns={columns}
                  rows={rows}
                  rowHeight={rowHeight}
                  rowMenu={rowMenu}
                  editedCell={editedCell}
                  updateCell={updateCell}
                  startCellEditing={startCellEditing}
                  cancelCellEditing={cancelCellEditing}
                  finishCellEditing={finishCellEditing}
                  getRowClassName={getRowClassName}
                  onCellClick={onCellClick}
                />
              </GridContent>
            )}
          </GridWindow>
        )}
      </GridAutoSizer>

      <GridPagination
        pagesCount={pagesCount}
        page={page}
        onPageChange={changePage}
        onLoadMore={onLoadMore}
      />
    </GridRoot>
  );
};

DataGrid.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isVirtualized: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,

      type: PropTypes.string,

      width: PropTypes.number,
      flex: PropTypes.number,

      isSticky: PropTypes.bool,
      isSortable: PropTypes.bool,
      isEditable: PropTypes.bool,

      valueGetter: PropTypes.func,
      valueFormatter: PropTypes.func,

      renderCell: PropTypes.func,
      renderEditCell: PropTypes.func,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
  rowHeight: PropTypes.number,
  title: PropTypes.string,
  initialSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  initialPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pagesCount: PropTypes.number,
  rowsCount: PropTypes.number,
  rowMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
  getRowId: PropTypes.func,
  getRowClassName: PropTypes.func,
  onCellClick: PropTypes.func,
  onCellChange: PropTypes.func,
  onCellEdited: PropTypes.func,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onReload: PropTypes.func,
  onLoadMore: PropTypes.func,
};

DataGrid.defaultProps = {
  id: null,
  className: null,
  isLoading: false,
  isVirtualized: false,
  rows: [],
  height: null,
  rowHeight: 40,
  title: null,
  initialSort: { order: 'none', field: null },
  initialPageSize: 100,
  pageSizeOptions: [50, 100, 150],
  pagesCount: 0,
  rowsCount: 0,
  rowMenu: null,
  getRowId: null,
  getRowClassName: null,
  onCellClick: null,
  onCellChange: null,
  onCellEdited: null,
  onSortChange: null,
  onPageChange: null,
  onPageSizeChange: null,
  onReload: null,
  onLoadMore: null,
};

export default DataGrid;
