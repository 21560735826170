import React from 'react';
import { SvgIcon } from '../elements';

const Information = props => {
  return (
    <SvgIcon {...props}>
      <path d="M16.219 5.625h4.51l-5.213-5.213v4.51c0 0.388 0.315 0.703 0.703 0.703z"></path>
      <path d="M4.969 24h14.062c1.163 0 2.109-0.946 2.109-2.109v-14.859h-4.922c-1.163 0-2.109-0.946-2.109-2.109v-4.922h-9.141c-1.163 0-2.109 0.946-2.109 2.109v19.781c0 1.163 0.946 2.109 2.109 2.109zM9.188 11.297h7.031c0.389 0 0.703 0.314 0.703 0.703s-0.315 0.703-0.703 0.703h-7.031c-0.389 0-0.703-0.314-0.703-0.703s0.314-0.703 0.703-0.703zM9.188 14.109h7.031c0.389 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703h-7.031c-0.389 0-0.703-0.315-0.703-0.703s0.314-0.703 0.703-0.703zM9.188 16.922h7.031c0.389 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703h-7.031c-0.389 0-0.703-0.315-0.703-0.703s0.314-0.703 0.703-0.703zM6.375 11.297c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703zM6.375 14.109c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703-0.703-0.315-0.703-0.703c0-0.388 0.315-0.703 0.703-0.703zM6.375 16.922c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703-0.703-0.315-0.703-0.703c0-0.388 0.315-0.703 0.703-0.703z"></path>
    </SvgIcon>
  );
};

export default Information;
