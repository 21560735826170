import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'components/elements';
import { SUPPLY_STATUSES } from 'constants/supply';

const getStatusObject = value => SUPPLY_STATUSES.find(status => status.value === value);

const SupplyStatusCellRender = ({ value }) => {
  const { t } = useTranslation();

  const status = getStatusObject(value);

  if (!status) {
    return null;
  }

  const { background, label } = status;

  return <Badge background={background}>{t(label)}</Badge>;
};

export default React.memo(
  SupplyStatusCellRender,
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);
