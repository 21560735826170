import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import XLSX from 'xlsx';
import config from 'config';
import message from './message';

export const copyToClipboard = str => {
  copy(str);
  // await navigator.clipboard.writeText(str);
  message('Ссылка скопирована', { type: 'success' });
};

export const getImageUrl = rawUrl => {
  if (!rawUrl) {
    return null;
  }
  if (rawUrl.includes('base64') || rawUrl.includes('http')) {
    return rawUrl;
  }
  if (rawUrl.startsWith('/')) {
    return `${config.FILESTORAGE_URL}${rawUrl}`;
  }
  return `${config.FILESTORAGE_URL}/${rawUrl}`;
};

export const exportToCSV = (data, fields, fileName = `csv-${new Date().toISOString()}`) => {
  const parser = new Parser({
    fields,
    withBOM: true,
  });
  const csv = parser.parse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, `${fileName}.csv`);
};

export const exportToXLSX = (sheets, fileName = `xlsx-${new Date().toISOString()}`) => {
  const workbook = XLSX.utils.book_new();
  sheets.forEach(({ name, data, header, cols }) => {
    const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true, header });
    worksheet['!cols'] = cols;
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      // eslint-disable-next-line no-empty-character-class
      name ? name.replace(/[:]|[/]|[\\]|[?]|[*]|[[]|[]]/gm, '-') : undefined,
    );
  });
  const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
  const blob = new Blob([workbookOut], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};

export const makeXLSXsheet = (gridColumns, data, name) => {
  const cols = [];
  const header = [];
  const headerRow = {};

  gridColumns.forEach(gridColumn => {
    cols.push({ wpx: gridColumn.width || 150 });
    header.push(gridColumn.field);
    headerRow[gridColumn.field] = gridColumn.header;
  });

  return {
    name,
    header,
    cols,
    data: [
      headerRow,
      ...data.map(item => {
        const result = {};
        gridColumns.forEach(col => {
          if (col.valueGetter) {
            result[col.field] = col.valueGetter({ rowData: item });
          } else {
            result[col.field] = item[col.field];
          }
        });
        return result;
      }),
    ],
  };
};

export const makeWatchFn = keys => (props, prevProps) => {
  return keys.some(key => props[key] !== prevProps[key]);
};

export const capitalize = string => {
  return string ? `${string[0].toUpperCase()}${string.slice(1)}` : string;
};

export const round = (value, fractionDigits = 2) => {
  return value ? Number(value.toFixed(fractionDigits)) : value;
};

export const isSafari = () => {
  return (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome') &&
    !navigator.userAgent.includes('CriOS')
  );
};

export const parseDate = (date, format, fixTimezone = false) => {
  const tmp = dayjs(date, format);
  const isValid = tmp.isValid();
  if (!isValid) {
    console.warn(`Invalid date: ${date}, return current date object`);
    return new Date();
  }
  if (fixTimezone) {
    return tmp.subtract(dayjs().utcOffset(), 'm').add(3, 'h').toDate();
  }
  return tmp.toDate();
};

export const parseNumber = value => {
  return Number(value.toString().replace(/,/g, '.'));
};
