import { useRef, useEffect } from 'react';
import { round } from 'utils/helpers';

const useSumRecalculation = ({ isEnabled = true, values, setFieldValue }) => {
  const mountRef = useRef(false);

  useEffect(() => {
    if (mountRef.current) {
      if (isEnabled && values.products) {
        const sumWithoutVat = values.products.reduce(
          (acc, product) => (acc += product.sumWithoutVat),
          0,
        );
        const sum = values.products.reduce(
          (acc, product) => (acc += product.sumWithoutVat + product.vatSum),
          0,
        );
        setFieldValue('sumWithoutVat', round(sumWithoutVat));
        setFieldValue('sum', round(sum));
      }
    } else {
      mountRef.current = true;
    }
  }, [isEnabled, values.products, setFieldValue]);
};

export default useSumRecalculation;
