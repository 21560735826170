import React from 'react';
import { InterfaceLanguageChanger } from 'components';
import { ArrowUp as ArrowUpIcon } from 'components/icons';
import telegram from 'img/telegram.svg';
import viber from 'img/viber.svg';
import whatsApp from 'img/whatsApp.svg';
import styles from './Footer.module.scss';

const Footer = ({ displayTop = true }) => (
  <footer className={styles.root}>
    {displayTop && (
      <div className={styles.backgroundDark}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div
              className={styles.scrollTop}
              onClick={() => {
                const mainContainer = document.getElementById('main-container');
                mainContainer.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
            >
              <ArrowUpIcon size={12} />
              Наверх
            </div>
          </div>
        </div>
      </div>
    )}
    <div className={styles.content}>
      <div className={styles.middle}>
        <div className={styles.icons}>
          <a className={styles.icon} href="https://wa.me/375295005860" alt="+375295005860">
            <img src={whatsApp} alt="whatsApp" />
            WhatsApp
          </a>
          <a className={styles.icon} href="https://telegram.me/bazar_dev" alt="@bazar_dev">
            <img src={telegram} alt="telegram" />
            Telegram
          </a>
          <a className={styles.icon} href="viber://chat?number=%2B375295005860" alt="+375295005860">
            <img src={viber} alt="viber" />
            Viber
          </a>
        </div>
        <a href="mailto:dev@bazar.store">dev@bazar.store</a>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.copyright}>2020, Bazar Development, LLC</div>
        <InterfaceLanguageChanger />
      </div>
    </div>
  </footer>
);

export default Footer;
