import React from 'react';
import { SvgIcon } from '../elements';

const Calendar = props => {
  return (
    <SvgIcon {...props}>
      <path d="M24 7.922c0-1.566 0-2.662 0-2.766 0-1.163-0.946-2.109-2.109-2.109h-0.281v2.578c0 0.388-0.315 0.703-0.703 0.703s-0.703-0.315-0.703-0.703c0-0.789 0-2.964 0-3.75 0-0.388-0.315-0.703-0.703-0.703s-0.703 0.315-0.703 0.703v1.172h-4.688v2.578c0 0.388-0.315 0.703-0.703 0.703s-0.703-0.315-0.703-0.703c0-0.789 0-2.964 0-3.75 0-0.388-0.315-0.703-0.703-0.703s-0.703 0.315-0.703 0.703v1.172h-4.688v2.578c0 0.388-0.315 0.703-0.703 0.703s-0.703-0.315-0.703-0.703c0-0.789 0-2.964 0-3.75 0-0.388-0.315-0.703-0.703-0.703s-0.703 0.315-0.703 0.703v1.172h-1.688c-1.163 0-2.109 0.946-2.109 2.109v2.766h24z"></path>
      <path d="M0 9.328v11.391c0 1.163 0.946 2.109 2.109 2.109h19.781c1.163 0 2.109-0.946 2.109-2.109 0-0.301 0-6.882 0-11.391-0.438 0-23.758 0-24 0zM6.75 19.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM6.75 16.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM6.75 13.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM12.75 19.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM12.75 16.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM12.75 13.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM18.75 19.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM18.75 16.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM18.75 13.078h-1.5c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h1.5c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703z"></path>
    </SvgIcon>
  );
};

export default Calendar;
