import React from 'react';
import { SvgIcon } from '../elements';

const Employees = props => {
  return (
    <SvgIcon {...props}>
      <path d="M20.536 12.811h-1.856c0.189 0.518 0.293 1.077 0.293 1.66v7.015c0 0.243-0.042 0.476-0.119 0.693h3.068c1.146 0 2.079-0.933 2.079-2.079v-3.824c0-1.91-1.554-3.464-3.464-3.464z"></path>
      <path d="M5.028 14.472c0-0.583 0.103-1.142 0.293-1.66h-1.856c-1.91 0-3.465 1.554-3.465 3.465v3.824c0 1.146 0.932 2.079 2.079 2.079h3.069c-0.077-0.217-0.119-0.45-0.119-0.693v-7.015z"></path>
      <path d="M14.122 11.007h-4.243c-1.91 0-3.465 1.554-3.465 3.464v7.015c0 0.383 0.31 0.693 0.693 0.693h9.786c0.383 0 0.693-0.31 0.693-0.693v-7.015c0-1.91-1.554-3.464-3.465-3.464z"></path>
      <path d="M12 1.82c-2.297 0-4.166 1.869-4.166 4.167 0 1.558 0.86 2.919 2.13 3.634 0.602 0.339 1.297 0.533 2.036 0.533s1.434-0.194 2.036-0.533c1.27-0.715 2.13-2.076 2.13-3.634 0-2.297-1.869-4.167-4.166-4.167z"></path>
      <path d="M4.684 5.704c-1.718 0-3.116 1.398-3.116 3.116s1.398 3.116 3.116 3.116c0.436 0 0.851-0.090 1.228-0.252 0.652-0.281 1.189-0.777 1.522-1.4 0.233-0.437 0.366-0.935 0.366-1.464 0-1.718-1.398-3.116-3.116-3.116z"></path>
      <path d="M19.317 5.704c-1.718 0-3.116 1.398-3.116 3.116 0 0.529 0.133 1.027 0.366 1.464 0.333 0.622 0.87 1.119 1.522 1.4 0.377 0.162 0.792 0.252 1.228 0.252 1.718 0 3.116-1.398 3.116-3.116s-1.398-3.116-3.116-3.116z"></path>
    </SvgIcon>
  );
};

export default Employees;
