import React from 'react';
import { useLocalStorage } from 'hooks';
import { Footer } from './Footer';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

const Layout = ({ children, withDarkstoreSelect = true }) => {
  const [isSidebarOpened, setIsSidebarOpened] = useLocalStorage(
    'Layout.isSidebarOpened',
    !window.matchMedia('only screen and (max-width: 768px)').matches,
  );

  return (
    <div id="main-container" className="main-container">
      <Sidebar
        isOpened={isSidebarOpened}
        withDarkstoreSelect={withDarkstoreSelect}
        setIsOpened={setIsSidebarOpened}
      />
      <Header isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened} />
      <main id="main" className="main">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
