import React, { useMemo } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DarkstoresSelect } from 'components';
import { useSetState } from 'hooks';
import { ProvidersAutocomplete } from 'components/autocompletes';
import { DataGrid, DatePicker, Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';
import { formatDate } from 'utils/formatters';
import WriteOffActDownload from './WriteOffActDownload';
import useColumns from './useColumns';
import useWriteOffs from './useWriteOffs';
import { exportWriteOffs } from './utils';

const TYPES = [
  { label: '+/- ', value: 'plus/minus' },
  { label: '+', value: 'plus' },
  { label: '- ', value: 'minus' },
];

const WriteOffs = () => {
  const { t } = useTranslation('writeOffs');

  const { partnerId, darkstores } = useSettingsContext();

  const reasons = useMemo(
    () => [
      { label: t('reasons.writeOff'), value: 'write-off' },
      { label: t('reasons.return'), value: 'return' },
      { label: t('reasons.moving'), value: 'moving' },
      { label: t('reasons.lack'), value: 'lack' },
      { label: t('reasons.reGrading'), value: 're-grading' },
    ],
    [t],
  );

  const [filters, setFilters] = useSetState({
    darkstore: darkstores[0],
    reason: reasons[0],
    provider: { id: 'all', name: t('shared:all') },
    dateFrom: new Date(),
    dateTo: new Date(),
    date: new Date(),
    type: TYPES[0],
  });

  const { isLoading, writeOffs } = useWriteOffs({
    darkstoreId: filters.darkstore && filters.darkstore.id,
    reason: filters.reason.value,
    partnerId,
    providerId: filters.provider.id,
    dateFrom: formatDate(filters.dateFrom, 'YYYY-MM-DD'),
    dateTo: formatDate(filters.dateTo, 'YYYY-MM-DD'),
    month: filters.date.getMonth() + 1,
    year: filters.date.getFullYear(),
    type: filters.type.value,
  });

  const columns = useColumns({ darkstoreId: filters.darkstore.id, darkstores });

  return (
    <Container fluid className="flex-fill d-flex flex-column">
      <h2>{t('title')}</h2>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>{t('filters.darkstore')}</Form.Label>
          <DarkstoresSelect
            hasAllOption={filters.reason.value === 'return'}
            value={filters.darkstore}
            onChange={darkstore => setFilters({ darkstore })}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('filters.reason')}</Form.Label>
          <Select
            options={reasons}
            value={filters.reason}
            onChange={reason => {
              if (filters.darkstore.id === 'all' && reason.value !== 'return') {
                setFilters({ darkstore: darkstores[0], reason });
              } else {
                setFilters({ reason });
              }
            }}
          />
        </Form.Group>
        {filters.reason.value === 'return' ? (
          <>
            <Form.Group as={Col}>
              <Form.Label>{t('filters.provider')}</Form.Label>
              <ProvidersAutocomplete
                hasAllOption
                value={filters.provider}
                onChange={provider => setFilters({ provider })}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{t('filters.period')}</Form.Label>
              <DatePicker
                isDateRange
                value={[filters.dateFrom, filters.dateTo]}
                onChange={([dateFrom, dateTo]) => setFilters({ dateFrom, dateTo })}
              />
            </Form.Group>
          </>
        ) : (
          <Form.Group as={Col}>
            <Form.Label>{t('filters.date')}</Form.Label>
            <DatePicker
              type="month"
              dateFormat="MM/YYYY"
              value={filters.date}
              onChange={date => setFilters({ date })}
            />
          </Form.Group>
        )}
        {['moving', 'lack', 're-grading'].includes(filters.reason.value) && (
          <Form.Group as={Col}>
            <Form.Label>{t('filters.type')}</Form.Label>
            <Select options={TYPES} value={filters.type} onChange={type => setFilters({ type })} />
          </Form.Group>
        )}
      </Row>
      <DataGrid
        isVirtualized
        className="mb-3"
        columns={columns}
        rows={writeOffs}
        isLoading={isLoading}
        getRowId={(row, index) => `${row.productId}-${index}`}
      />
      <div className="d-flex justify-content-between">
        <WriteOffActDownload darkstoreId={filters.darkstore.id} />
        <Button
          variant="outline-primary"
          onClick={() => {
            exportWriteOffs({ darkstoreId: filters.darkstore.id, darkstores, columns, writeOffs });
          }}
        >
          {t('export')}
        </Button>
      </div>
    </Container>
  );
};

export default WriteOffs;
