import React from 'react';
import { SvgIcon } from '../elements';

const Marketing = props => {
  return (
    <SvgIcon {...props}>
      <path d="M6.030 17.004l-0.043-0.003c-0.334-0.020-0.656-0.082-0.962-0.178l-2.139 3.703c-0.196 0.339-0.077 0.773 0.265 0.965l2.105 1.181c0.336 0.188 0.761 0.072 0.953-0.262l3.122-5.407h-3.302z"></path>
      <path d="M17.907 1.343l-6.353 3.889v11.201l6.361 3.779c0.469 0.279 1.063-0.059 1.063-0.605v-17.665c0-0.549-0.602-0.887-1.071-0.6z"></path>
      <path d="M2.275 13.127v-4.723c0-0.271 0.029-0.534 0.084-0.789h-1.023c-0.738 0-1.336 0.598-1.336 1.335v3.63c0 0.738 0.598 1.335 1.336 1.335h1.022c-0.054-0.255-0.083-0.519-0.083-0.789z"></path>
      <path d="M10.147 15.598h-4.074c-1.321-0.080-2.391-1.15-2.391-2.47v-4.723c0-1.32 1.070-2.39 2.391-2.31h4.074v9.504z"></path>
      <path d="M22.065 16.514c-0.18 0-0.36-0.069-0.497-0.206l-1.419-1.418c-0.275-0.275-0.275-0.72 0-0.994s0.72-0.275 0.995 0l1.419 1.418c0.275 0.274 0.275 0.72 0 0.994-0.137 0.137-0.317 0.206-0.497 0.206z"></path>
      <path d="M20.646 8.076c-0.18 0-0.36-0.069-0.497-0.206-0.275-0.275-0.275-0.72 0-0.994l1.419-1.418c0.275-0.275 0.72-0.275 0.995 0s0.275 0.72 0 0.994l-1.419 1.418c-0.137 0.137-0.317 0.206-0.497 0.206z"></path>
      <path d="M23.297 11.586h-2.007c-0.389 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h2.007c0.389 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703z"></path>
    </SvgIcon>
  );
};

export default Marketing;
