import React, { useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import clsx from 'clsx';
import {
  More as MoreIcon,
  Copy as CopyIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Check as CheckIcon,
} from 'components/icons';
import styles from './Menu.module.scss';

// TODO: will be removed
const renderIcon = iconName => {
  switch (iconName) {
    case 'copy':
      return <CopyIcon color="inherit" size={14} />;
    case 'edit':
      return <EditIcon color="inherit" size={14} />;
    case 'close':
    case 'cancel':
      return <CloseIcon color="inherit" size={14} />;
    case 'delete':
    case 'remove':
      return <DeleteIcon color="inherit" size={14} />;
    case 'link':
      return <LinkIcon color="inherit" size={14} />;
    case 'check':
      return <CheckIcon color="inherit" size={14} />;
    default:
      return null;
  }
};

const Menu = ({ className, items = [], children }) => {
  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <span
        className={clsx(styles.trigger, className)}
        onClick={e => setReferenceElement(!!referenceElement ? null : e.currentTarget)}
      >
        {children || <MoreIcon color={!!referenceElement ? 'primary' : 'default'} size={18} />}
      </span>

      <Overlay
        flip
        rootClose
        show={!!referenceElement}
        target={referenceElement}
        placement="bottom-end"
        popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
        onHide={() => setReferenceElement(null)}
      >
        <Popover className={styles.popper}>
          {items.map((item, index) => (
            <button
              key={index}
              className={styles.item}
              onClick={e => {
                item.onClick && item.onClick(e);
                setReferenceElement(null);
              }}
            >
              {renderIcon(item.icon)}
              {item.title}
            </button>
          ))}
        </Popover>
      </Overlay>
    </>
  );
};

export default Menu;
