import React from 'react';
import { Select } from 'components/elements';
import useProviderProducts from './useProviderProducts';

const ProviderProductsAutocomplete = ({ providerId, placeholder, value, onChange }) => {
  const { isLoading, products } = useProviderProducts({ providerId });

  return (
    <Select
      isSearchable
      placeholder={placeholder}
      isLoading={isLoading}
      options={products}
      getOptionLabel={option => `${option.cod} ${option.name}`}
      getOptionValue={option => option.productId}
      value={value}
      onChange={onChange}
    />
  );
};

export default ProviderProductsAutocomplete;
