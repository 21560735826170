import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { AuthContextProvider } from 'contexts/AuthContext';
import { rollbarConfig } from 'lib/rollbar';
import App from './App';
import config from './config';
import './lib/dayjs';
import './lib/i18n';
import './lib/polyfill';
import queryClient from './lib/queryClient';
import './lib/yup';
import './styles/index.scss';
import { MessagesOutlet } from './utils/message';

if (config.IS_DEVELOPMENT_ENV) {
  document.title = 'DEV: Bazar Admin';
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Suspense fallback={<Spinner animation="border" variant="primary" />}>
            <QueryClientProvider client={queryClient}>
              <AuthContextProvider>
                <App />
                <MessagesOutlet />
              </AuthContextProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
