import React from 'react';
import { SvgIcon } from '../elements';

const Reports = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.143 6h-4.286v-5.143c0-0.473-0.384-0.857-0.857-0.857h-17.143c-0.473 0-0.857 0.384-0.857 0.857v19.714c0 1.894 1.535 3.429 3.429 3.429h17.143c1.894 0 3.429-1.535 3.429-3.429v-13.714c0-0.473-0.384-0.857-0.857-0.857zM6.857 4.286h5.143c0.473 0 0.857 0.384 0.857 0.857s-0.384 0.857-0.857 0.857h-5.143c-0.473 0-0.857-0.384-0.857-0.857s0.384-0.857 0.857-0.857zM14.571 20.571h-10.286c-0.473 0-0.857-0.384-0.857-0.857s0.384-0.857 0.857-0.857h10.286c0.473 0 0.857 0.384 0.857 0.857s-0.384 0.857-0.857 0.857zM14.571 17.143h-10.286c-0.473 0-0.857-0.384-0.857-0.857s0.384-0.857 0.857-0.857h10.286c0.473 0 0.857 0.384 0.857 0.857s-0.384 0.857-0.857 0.857zM14.571 13.714h-10.286c-0.473 0-0.857-0.384-0.857-0.857s0.384-0.857 0.857-0.857h10.286c0.473 0 0.857 0.384 0.857 0.857s-0.384 0.857-0.857 0.857zM14.571 10.286h-10.286c-0.473 0-0.857-0.384-0.857-0.857s0.384-0.857 0.857-0.857h10.286c0.473 0 0.857 0.384 0.857 0.857s-0.384 0.857-0.857 0.857zM22.286 20.571c0 0.947-0.768 1.714-1.714 1.714s-1.714-0.768-1.714-1.714v-12.857h3.429v12.857z"></path>
    </SvgIcon>
  );
};

export default Reports;
