import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';

const DarkstoresSelect = ({
  isMulti,
  isInvalid,
  hasAllOption,
  placeholder,
  value,
  getOptionClassName,
  onChange,
}) => {
  const { t } = useTranslation('shared');

  const { darkstores } = useSettingsContext();

  const options = hasAllOption ? [{ id: 'all', name: t('all') }, ...darkstores] : darkstores;

  return (
    <Select
      isMulti={isMulti}
      isInvalid={isInvalid}
      placeholder={placeholder}
      options={options}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      getOptionClassName={getOptionClassName}
      value={value}
      onChange={onChange}
    />
  );
};

export default DarkstoresSelect;
