import { formatDate } from 'utils/formatters';
import { exportToXLSX, round } from 'utils/helpers';

const TRANSLATION_PREFIX = `supply:columns`;

const exportSupply = ({
  id,
  darkstoreName,
  products,
  sum,
  darkstoreAddress,
  darkstorePhone,
  providerName,
  date,
  t,
}) => {
  exportToXLSX(
    [
      {
        name: `${darkstoreName}_${id}`,
        header: ['cod', 'name', 'weight', 'qty', 'priceWithoutVat', 'vat', 'priceWithVat', 'sum'],
        cols: [
          { wpx: 100 },
          { wpx: 250 },
          { wpx: 100 },
          { wpx: 125 },
          { wpx: 175 },
          { wpx: 100 },
          { wpx: 150 },
          { wpx: 125 },
        ],
        data: [
          {
            cod: t(`${TRANSLATION_PREFIX}.barcode`),
            name: t(`${TRANSLATION_PREFIX}.name`),
            weight: t(`${TRANSLATION_PREFIX}.weight`),
            qty: t(`${TRANSLATION_PREFIX}.qty`),
            priceWithoutVat: t(`${TRANSLATION_PREFIX}.priceWithoutVat`),
            vat: t(`${TRANSLATION_PREFIX}.vat`),
            priceWithVat: t(`${TRANSLATION_PREFIX}.priceWithVat`),
            sum: t(`${TRANSLATION_PREFIX}.sum`),
            sku: t(`${TRANSLATION_PREFIX}.sku`),
          },
          ...products.map(({ cod, name, weight, qty, priceWithoutVat, vat, sku }) => ({
            cod,
            name,
            weight,
            qty,
            priceWithoutVat,
            vat,
            priceWithVat: round(priceWithoutVat * (1 + vat / 100)),
            sum: round(priceWithoutVat * (1 + vat / 100) * qty),
            sku,
          })),
          { cod: '' },
          { cod: '', name: t(`${TRANSLATION_PREFIX}.supplySum`), weight: sum },
          { cod: '' },
          { cod: '', name: t(`${TRANSLATION_PREFIX}.deliveryAddress`), weight: darkstoreAddress },
          { cod: '', name: t(`${TRANSLATION_PREFIX}.contactPhone`), weight: darkstorePhone },
        ],
      },
    ],
    `${providerName}-${formatDate(date, 'DD.MM.YYYY')}`,
  );
};

export default exportSupply;
