import { useContext } from 'react';
import NewRequestContext from './NewRequestContext';

const useNewRequestContext = () => {
  const context = useContext(NewRequestContext);

  if (!context) {
    throw new Error('useAuthContext can only be used inside NewRequestContext');
  }

  return context;
};

export default useNewRequestContext;
