import { useQuery, useQueryClient } from 'react-query';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

const getSubCategories = async ({ categoryId }) => {
  const subCategories = await api.get(`products/categories/filters/${categoryId}`);
  return subCategories.sort((a, b) => a.sort - b.sort);
};

const useSubCategories = ({ categoryId }) => {
  const queryClient = useQueryClient();

  const { language } = useSettingsContext();

  const { isLoading, data = [] } = useQuery(
    ['subCategories', { categoryId, language }],
    () => getSubCategories({ categoryId }),
    { enabled: !!categoryId },
  );

  return {
    isLoading,
    subCategories: data,
    setSubCategories: subCategories => {
      queryClient.setQueryData(['subCategories', { categoryId, language }], () => subCategories);
    },
  };
};

export default useSubCategories;
