import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { useAuthContext } from 'contexts/AuthContext';

const Protected = ({ children }) => {
  const { pathname } = useLocation();

  const { isAuthenticated, user } = useAuthContext();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!user) {
    return null;
  }

  const readable = Object.keys(user.acl).filter(key => user.acl[key].read);
  const isNotReadable = !readable.some(url => pathname.includes(url));

  if (isNotReadable) {
    return <Redirect to={readable[0]} />;
  }

  return children;
};

export default Protected;
