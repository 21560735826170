import { useQuery } from 'react-query';
import api from 'utils/api';

const useWriteOffs = ({
  darkstoreId,
  reason,
  partnerId,
  providerId,
  dateFrom,
  dateTo,
  month,
  year,
  type,
}) => {
  const { isLoading, data = [] } = useQuery(
    [
      'writeOffs',
      { darkstoreId, reason, partnerId, providerId, dateFrom, dateTo, month, year, type },
    ],
    async () => {
      let data = [];

      switch (reason) {
        case 'write-off':
          data = await api.get(`admin/assortiment/writeOff`, { darkstoreId, month, year });
          break;
        case 'return': {
          const params = {
            partnerId,
            dateFrom,
            dateTo,
          };
          if (darkstoreId !== 'all') {
            params.darkstoreId = darkstoreId;
          }
          if (providerId !== 'all') {
            params.providerId = providerId;
          }
          data = await api.get('admin/returns', params);
          break;
        }
        default: {
          const params = {
            darkstoreId,
            reason,
            month,
            year,
          };
          if (type !== 'plus/minus') {
            params.type = type;
          }
          data = await api.get(`admin/assortiment/correctQty`, params);
        }
      }

      return data.map(item => ({
        ...item,
        employee: `${item.employeeSurname ? item.employeeSurname : ''} ${
          item.employeeName ? item.employeeName : ''
        }`,
      }));
    },
  );

  return { isLoading, writeOffs: data };
};

export default useWriteOffs;
