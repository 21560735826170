import React from 'react';
import { Select } from 'components/elements';

const getCurrentHour = () => {
  const now = new Date();
  return now.getHours();
};

const generateTimeOptions = allHours => {
  const currentHour = getCurrentHour();
  const times = [];

  const startHour = allHours ? 0 : currentHour + 1;
  const endHour = 24;

  for (let hour = startHour; hour < endHour; hour++) {
    times.push({
      label: `${hour.toString().padStart(2, '0')}:00`,
      value: `${hour.toString().padStart(2, '0')}:00`,
    });
  }

  return times;
};

const TimeSelect = ({ className, disabled, value, onChange, allHours }) => {
  const times = generateTimeOptions(allHours);

  return (
    <Select
      className={className}
      disabled={disabled}
      placeholder="Время"
      options={times}
      value={value}
      onChange={onChange}
    />
  );
};

export default TimeSelect;
