import React from 'react';
import { SvgIcon } from '../elements';

const Delete = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.875 3h-4.125v-0.75c0-1.243-1.007-2.25-2.25-2.25h-3c-1.243 0-2.25 1.007-2.25 2.25v0.75h-4.125c-1.036 0-1.875 0.839-1.875 1.875v1.5c0 0.414 0.336 0.75 0.75 0.75h18c0.414 0 0.75-0.336 0.75-0.75v-1.5c0-1.036-0.839-1.875-1.875-1.875zM9.75 2.25c0-0.413 0.337-0.75 0.75-0.75h3c0.413 0 0.75 0.337 0.75 0.75v0.75h-4.5v-0.75z"></path>
      <path d="M3.673 8.625c-0.134 0-0.24 0.112-0.234 0.246l0.619 12.986c0.057 1.202 1.044 2.143 2.247 2.143h11.39c1.203 0 2.19-0.941 2.247-2.143l0.619-12.986c0.006-0.134-0.1-0.246-0.234-0.246h-16.653zM15 10.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v9.75c0 0.414-0.336 0.75-0.75 0.75s-0.75-0.336-0.75-0.75v-9.75zM11.25 10.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v9.75c0 0.414-0.336 0.75-0.75 0.75s-0.75-0.336-0.75-0.75v-9.75zM7.5 10.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v9.75c0 0.414-0.336 0.75-0.75 0.75s-0.75-0.336-0.75-0.75v-9.75z"></path>
    </SvgIcon>
  );
};

export default Delete;
