import React from 'react';
import { Check as CheckIcon } from '../../icons';

const CheckCellRender = ({ value }) => {
  return value ? <CheckIcon color="primary" size={18} /> : '-';
};

export default React.memo(
  CheckCellRender,
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);
