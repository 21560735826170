import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSettingsContext } from 'contexts/SettingsContext';
import { usePaymentMethods } from 'hooks/constants';
import api from 'utils/api';
import { Select } from '../elements';

const usePaymentStatus = ({ orderId, enabled }) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['payment-status', { orderId }],
    queryFn: async () => {
      const { status } = await api.get(`/admin/orders/checkPayment/${orderId}`);
      return status;
    },
    enabled,
  });
  return { isLoading, status: data, refetch };
};

const OrderPaymentStatus = ({
  isInsideTable,
  disabled,
  orderId,
  paymentValue,
  paymentStatus,
  onChange,
}) => {
  const { paymentMethods } = usePaymentMethods();

  const value = useMemo(
    () => paymentMethods.find(method => method.value === paymentValue),
    [paymentMethods, paymentValue],
  );

  const { partnerId } = useSettingsContext();
  //payment methods ids
  const isPaymentConfigured = [0, 1, 2, 4].includes(partnerId);
  const isShouldBeChecked = [3, 4, 5, 6].includes(paymentValue);

  const { isLoading, status, refetch } = usePaymentStatus({
    orderId,
    enabled: !!(isPaymentConfigured && isShouldBeChecked && orderId && !paymentStatus),
  });

  const paid = paymentStatus || status;
  let background = 'transparent';
  if (isPaymentConfigured && isShouldBeChecked) {
    if (isLoading) {
      background = '#FF7A00';
    } else {
      background = paid ? '#66AF37' : '#E82938';
    }
  }

  if (isPaymentConfigured && isShouldBeChecked) {
    return (
      <div className="d-flex align-items-center">
        {orderId && (
          <span
            style={{
              background,
              width: '8px',
              minWidth: '8px',
              height: '8px',
              borderRadius: '4px',
              marginRight: '8px',
            }}
            onClick={() => {
              if (!paid) {
                refetch();
              }
            }}
          />
        )}
        <Select
          isBordered={!isInsideTable}
          disabled={disabled || paymentStatus}
          size={isInsideTable ? 'small' : 'normal'}
          options={paymentMethods}
          value={value}
          onChange={newMethod =>
            onChange(
              { orderId, paymentMethod: newMethod.value },
              { orderId, paymentMethod: paymentValue },
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      {isInsideTable && (
        <span
          style={{
            background,
            width: '8px',
            minWidth: '8px',
            height: '8px',
            borderRadius: '4px',
            marginRight: '8px',
          }}
        />
      )}
      <Select
        isBordered={!isInsideTable}
        disabled={disabled}
        size={isInsideTable ? 'small' : 'normal'}
        options={paymentMethods}
        value={value}
        onChange={newMethod =>
          onChange(
            { orderId, paymentMethod: newMethod.value },
            { orderId, paymentMethod: paymentValue },
          )
        }
      />
    </div>
  );
};

export default OrderPaymentStatus;
