import React from 'react';
import { SvgIcon } from '../elements';

const More = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 6c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.65 0-3 1.35-3 3s1.35 3 3 3zM12 9c-1.65 0-3 1.35-3 3s1.35 3 3 3c1.65 0 3-1.35 3-3s-1.35-3-3-3zM12 18c-1.65 0-3 1.35-3 3s1.35 3 3 3c1.65 0 3-1.35 3-3s-1.35-3-3-3z"></path>
    </SvgIcon>
  );
};

export default More;
