import React from 'react';
import { SvgIcon } from '../elements';

const Info = React.forwardRef((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M10.8 6h2.4v2.4h-2.4v-2.4zM10.8 10.8h2.4v7.2h-2.4v-7.2zM12 0c-6.624 0-12 5.376-12 12s5.376 12 12 12c6.624 0 12-5.376 12-12s-5.376-12-12-12zM12 21.6c-5.292 0-9.6-4.308-9.6-9.6s4.308-9.6 9.6-9.6c5.292 0 9.6 4.308 9.6 9.6s-4.308 9.6-9.6 9.6z"></path>
    </SvgIcon>
  );
});

export default Info;
