import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './GridLoadingOverlay.module.scss';

const GridLoadingOverlay = () => (
  <div className={styles.root}>
    <Spinner animation="border" variant="primary" />
  </div>
);

export default GridLoadingOverlay;
