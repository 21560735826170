import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Checkbox } from 'components/elements';
import styles from './SelectMenu.module.scss';

const SelectMenu = ({ isMulti, items, highlightedIndex, getMenuProps, getItemProps }) => {
  if (items.length === 0) {
    return (
      <ul {...getMenuProps()} className={styles.root}>
        <li className={styles.empty}>Нет результатов</li>
      </ul>
    );
  }

  // menu with sections
  if (items[0].items) {
    return (
      <ul {...getMenuProps()} className={styles.root}>
        {
          items.reduce(
            (result, section, sectionIndex) => {
              result.sections.push(
                <Fragment key={sectionIndex}>
                  <li className={styles.groupLabel}>{section.label}</li>
                  {section.items.map((sectionItem, sectionItemIndex) => {
                    const index = result.itemIndex++;
                    return (
                      <li
                        {...getItemProps({
                          item: sectionItem,
                          index: index,
                        })}
                        key={sectionItemIndex}
                        style={highlightedIndex === index ? { border: '1px solid #ff7a00' } : {}}
                        className={clsx(
                          styles.item,
                          sectionItem.className,
                          !isMulti && sectionItem.isSelected && styles.itemSelected,
                        )}
                      >
                        {isMulti && (
                          <Checkbox
                            className={styles.itemCheckbox}
                            isChecked={sectionItem.isSelected}
                          />
                        )}
                        <span className={styles.itemLabel}>{sectionItem.label}</span>
                      </li>
                    );
                  })}
                </Fragment>,
              );

              return result;
            },
            { sections: [], itemIndex: 0 },
          ).sections
        }
      </ul>
    );
  }

  return (
    <ul {...getMenuProps()} className={styles.root}>
      {items.map((item, index) => {
        return (
          <li
            {...getItemProps({ item, index })}
            key={`${item.value}-${index}`}
            style={highlightedIndex === index ? { border: '1px solid #ff7a00' } : {}}
            className={clsx(
              styles.item,
              item.className,
              !isMulti && item.isSelected && styles.itemSelected,
            )}
          >
            {isMulti && <Checkbox className={styles.itemCheckbox} isChecked={item.isSelected} />}
            <span className={styles.itemLabel}>{item.label}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default SelectMenu;
