import React from 'react';
import { SvgIcon } from '../elements';

const Orders = props => {
  return (
    <SvgIcon {...props}>
      <path d="M22.382 23.211l-1.824-17.371c-0.038-0.364-0.345-0.64-0.711-0.64h-2v3.003c0 1.182-0.961 2.143-2.143 2.143s-2.143-0.961-2.143-2.143v-3.003h-3.124v3.003c0 1.182-0.961 2.143-2.143 2.143s-2.143-0.961-2.143-2.143v-3.003h-2c-0.366 0-0.672 0.276-0.71 0.64l-1.824 17.371c-0.021 0.201 0.044 0.402 0.18 0.553s0.329 0.236 0.531 0.236h19.343c0.203 0 0.396-0.086 0.531-0.236s0.201-0.351 0.18-0.553z"></path>
      <path d="M7.581 8.203c0 0.395 0.32 0.714 0.714 0.714s0.714-0.32 0.714-0.714v-3.784c0-1.649 1.342-2.99 2.991-2.99s2.991 1.342 2.991 2.99v3.784c0 0.394 0.32 0.714 0.714 0.714s0.714-0.32 0.714-0.714v-3.784c0-2.437-1.983-4.419-4.419-4.419s-4.419 1.982-4.419 4.419v3.784z"></path>
    </SvgIcon>
  );
};

export default Orders;
