import React from 'react';
import { SvgIcon } from '../elements';

const List = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0.933 2.667h22.133c0.515 0 0.933 0.418 0.933 0.933v0.8c0 0.515-0.418 0.933-0.933 0.933h-22.133c-0.515 0-0.933-0.418-0.933-0.933v-0.8c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M0.933 8h22.133c0.515 0 0.933 0.418 0.933 0.933v0.8c0 0.515-0.418 0.933-0.933 0.933h-22.133c-0.515 0-0.933-0.418-0.933-0.933v-0.8c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M0.933 13.333h22.133c0.515 0 0.933 0.418 0.933 0.933v0.8c0 0.515-0.418 0.933-0.933 0.933h-22.133c-0.515 0-0.933-0.418-0.933-0.933v-0.8c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M0.933 18.667h22.133c0.515 0 0.933 0.418 0.933 0.933v0.8c0 0.515-0.418 0.933-0.933 0.933h-22.133c-0.515 0-0.933-0.418-0.933-0.933v-0.8c0-0.515 0.418-0.933 0.933-0.933z"></path>
    </SvgIcon>
  );
};

export default List;
