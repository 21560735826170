import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Select } from 'components/elements';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

const ProvidersAutocomplete = ({
  isMulti,
  isInvalid,
  hasAllOption,
  placeholder,
  value,
  onChange,
}) => {
  const { t } = useTranslation('shared');

  const { darkstoreId } = useSettingsContext();

  const { isLoading, data: providers = [] } = useQuery(
    ['autocompletes/providers', { darkstoreId }],
    async () => {
      const data = await api.post('admin/providers/list', {
        darkstoreId,
        page: 1,
        pageSize: 1000,
      });
      return data.rows;
    },
  );

  const options = hasAllOption ? [{ id: 'all', name: t('all') }, ...providers] : providers;

  return (
    <Select
      isSearchable
      isMulti={isMulti}
      isLoading={isLoading}
      isInvalid={isInvalid}
      placeholder={placeholder}
      options={options}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      value={value}
      onChange={onChange}
    />
  );
};

export default ProvidersAutocomplete;
