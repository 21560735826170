import React, { useReducer } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuthContext } from 'contexts/AuthContext';
import logger from 'utils/logger';
import SettingsContext from './SettingsContext';
import { getInitialSettings } from './helpers';
import useDarkstores from './useDarkstores';
import useLanguages from './useLanguages';

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_SETTINGS':
      return action.payload;
    case 'DARKSTORE_ID_CHANGED':
      return { ...state, darkstoreId: action.payload };
    case 'LANGUAGE_CHANGED':
      return { ...state, language: action.payload };
    default:
      return state;
  }
};

const SettingsContextProvider = ({ children }) => {
  const {
    user: { partnerId, providerId, darkstores: availableDarkstores },
  } = useAuthContext();

  const [state, dispatch] = useReducer(logger(reducer));

  const languages = useLanguages({ partnerId });

  const { darkstores } = useDarkstores({
    partnerId,
    availableDarkstores,
    onSuccess: darkstores => {
      const initialSettings = getInitialSettings({ partnerId, darkstores, languages });
      dispatch({ type: 'INIT_SETTINGS', payload: initialSettings });
    },
  });

  const setDarkstoreId = darkstoreId => {
    localStorage.setItem('darkstoreId', darkstoreId);
    dispatch({ type: 'DARKSTORE_ID_CHANGED', payload: darkstoreId });
    // TODO: will be removed after refactoring api fetch hooks
    document.location.reload();
  };

  const setLanguage = language => {
    localStorage.setItem('language', language);
    dispatch({ type: 'LANGUAGE_CHANGED', payload: language });
  };

  if (!state) {
    return <Spinner animation="border" variant="primary" className="my-3" />;
  }

  return (
    <SettingsContext.Provider
      value={{
        partnerId,
        providerId,
        darkstores,
        languages,
        ...state,
        setDarkstoreId,
        setLanguage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
