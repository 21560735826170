import { useMemo } from 'react';
import { copyToClipboard } from 'utils/helpers';

const makeRowMenu = ({ t, id, modalState }) => [
  {
    title: t('shared:buttons.copyLink'),
    icon: 'copy',
    onClick: row => {
      copyToClipboard(`${window.location.origin}/assortiment/products/${row.productId}`);
    },
  },
  {
    title: t('shared:buttons.delete'),
    icon: 'delete',
    onClick: row => {
      modalState.set({
        isOpened: true,
        key: 'deleteProduct',
        supplyId: id,
        productId: row.productId,
        productName: row.name,
        maxQty: row.qty,
      });
    },
  },
];

const useRowMenu = ({ t, id, modalState }) => {
  return useMemo(() => makeRowMenu({ t, id, modalState }), [t, id, modalState]);
};

export default useRowMenu;
