import React from 'react';
import { SvgIcon } from '../elements';

const Reload = props => {
  return (
    <SvgIcon {...props}>
      <path d="M13.789 22.927c-0.049-0.563-0.509-0.97-1.073-0.916-4.541 0.304-8.773-2.498-10.198-6.833-1.401-4.154 0.083-8.631 3.492-11.173l-0.024 2.145c0 0.563 0.46 1.019 1.019 1.019 0.509 0 0.97-0.407 1.019-0.97l0.049-5.099c0.049-0.563-0.407-1.019-0.97-1.019l-5.094-0.049c-0.563 0-1.019 0.407-1.019 0.97-0.049 0.563 0.407 1.019 0.97 1.019l3.35 0.044c-4.487 2.993-6.382 8.606-4.678 13.725 1.631 4.947 6.221 8.209 11.374 8.209 0.255 0 0.563 0 0.867 0 0.558-0.049 0.97-0.509 0.916-1.073z"></path>
      <path d="M21.998 21.708l-2.919-0.034c4.178-3.061 5.946-8.474 4.296-13.426-1.68-5.256-6.73-8.621-12.236-8.214-0.563 0.049-0.97 0.509-0.916 1.073 0.049 0.563 0.509 0.97 1.073 0.916 4.541-0.358 8.773 2.498 10.198 6.833 1.352 4.11-0.088 8.636-3.551 11.178l0.029-2.459c0.049-0.563-0.407-1.019-0.97-1.019-0.563-0.049-1.019 0.407-1.019 0.97l-0.049 5.099c-0.049 0.563 0.407 1.019 0.97 1.019l5.099 0.049c0.563 0 1.019-0.46 0.97-0.97 0.049-0.558-0.411-1.014-0.975-1.014z"></path>
    </SvgIcon>
  );
};

export default Reload;
