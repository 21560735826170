import React from 'react';
import { SvgIcon } from '../elements';

const Logout = props => {
  return (
    <SvgIcon {...props}>
      <path d="M15 13c-0.553 0-1 0.448-1 1v4c0 0.551-0.448 1-1 1h-3v-15c0-0.854-0.544-1.617-1.362-1.901l-0.296-0.099h4.658c0.552 0 1 0.449 1 1v3c0 0.552 0.447 1 1 1s1-0.448 1-1v-3c0-1.654-1.346-3-3-3h-10.75c-0.038 0-0.070 0.017-0.107 0.022-0.048-0.004-0.094-0.022-0.143-0.022-1.103 0-2 0.897-2 2v18c0 0.854 0.544 1.617 1.362 1.901l6.018 2.006c0.204 0.063 0.407 0.093 0.62 0.093 1.103 0 2-0.897 2-2v-1h3c1.654 0 3-1.346 3-3v-4c0-0.552-0.447-1-1-1z"></path>
      <path d="M23.707 9.292l-4-4c-0.286-0.286-0.716-0.372-1.090-0.217s-0.617 0.52-0.617 0.924v3h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1h4v3c0 0.404 0.244 0.769 0.617 0.924s0.804 0.069 1.090-0.217l4-4c0.391-0.391 0.391-1.023 0-1.414z"></path>
    </SvgIcon>
  );
};

export default Logout;
