import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Protected } from 'components';
import {
  Analytics,
  Assortiment,
  Blacklist,
  CashAccounting,
  ClientAdd,
  ClientEdit,
  Clients,
  Employees,
  Info,
  Layout,
  Login,
  Marketing,
  NewRequest,
  Order,
  Orders,
  Provider,
  ProviderProducts,
  Providers,
  ProviderSupplies,
  Registry,
  Reports,
  Supply,
  Supplies,
  WriteOffs,
  ProviderSupply,
  DarkstoreZoneAdd,
  DarkstoreZoneEdit,
  Users,
  Monitoring,
  PaidDelivery,
  StopList,
} from 'features';
import { useAuthContext } from 'contexts/AuthContext';
import { ModalsContextProvider } from 'contexts/ModalsContext';
import { NewRequestProvider } from 'contexts/NewRequestContext';
import { SettingsContextProvider } from 'contexts/SettingsContext';

const App = () => {
  const { isAuthenticated, user } = useAuthContext();

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    );
  }

  if (user.role === 'provider') {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Protected>
          <SettingsContextProvider>
            <Layout withDarkstoreSelect={false}>
              <Switch>
                <Redirect exact from="/" to="/products" />
                <Route path="/products">
                  <ProviderProducts />
                </Route>
                <Route path={['/supplies/new', '/supplies/:id']}>
                  <ProviderSupply />
                </Route>
                <Route path="/supplies">
                  <ProviderSupplies />
                </Route>
              </Switch>
            </Layout>
          </SettingsContextProvider>
        </Protected>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Protected>
        <SettingsContextProvider>
          <NewRequestProvider>
            <ModalsContextProvider>
              <Layout>
                <Switch>
                  <Redirect exact from="/" to="/orders" />
                  <Route path="/assortiment" component={Assortiment} />
                  <Route path="/stop-list" component={StopList} />
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/cash-accounting" component={CashAccounting} />
                  <Route path="/employees" component={Employees} />
                  <Route path="/info/darkstore-zones/new" component={DarkstoreZoneAdd} />
                  <Route path="/info/darkstore-zones/:zoneId" component={DarkstoreZoneEdit} />
                  <Route path="/info" component={Info} />
                  <Route path="/clients/blacklist" component={Blacklist} />
                  <Route path="/clients/new" component={ClientAdd} />
                  <Route path="/clients/:clientId" component={ClientEdit} />
                  <Route path="/clients" component={Clients} />
                  <Route path="/marketing" component={Marketing} />
                  <Route path="/monitoring" component={Monitoring} />
                  <Route path="/supplies/new-request" component={NewRequest} />
                  <Route path="/orders/:orderId" component={Order} />
                  <Route path="/orders" component={Orders} />
                  <Route path="/paid-delivery" component={PaidDelivery} />
                  <Route path={['/providers/new', '/providers/:id']} component={Provider} />
                  <Route path="/providers" component={Providers} />
                  <Route path="/registry" component={Registry} />
                  <Route path="/reports" component={Reports} />
                  <Route path={['/supplies/new', '/supplies/:id']} component={Supply} />
                  <Route path="/supplies" component={Supplies} />
                  <Route path="/write-offs" component={WriteOffs} />
                  <Route path="/users" component={Users} />
                </Switch>
              </Layout>
            </ModalsContextProvider>
          </NewRequestProvider>
        </SettingsContextProvider>
      </Protected>
    </Switch>
  );
};

export default App;
