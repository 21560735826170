import { useReducer, useEffect, useRef } from 'react';
import { getSortOrder, prepareColumns, prepareRows } from './helpers';

const reducer = (state, action) => {
  switch (action.type) {
    case 'COLUMNS_CHANGED': {
      const { headerGroups, columns } = action.payload;
      return {
        ...state,
        headerGroups,
        columns,
      };
    }

    case 'ROWS_CHANGED':
      return {
        ...state,
        rows: action.payload,
      };

    case 'SORT_CHANGED':
      return {
        ...state,
        sort: action.payload,
      };

    case 'PAGE_CHANGED':
      return {
        ...state,
        page: action.payload,
      };

    case 'PAGE_SIZE_CHANGED':
      return {
        ...state,
        pageSize: action.payload,
      };

    case 'CELL_CHANGED': {
      const { rowId, field, value } = action.payload;
      const index = state.rows.findIndex(row => row.id === rowId);
      const changedRows = [...state.rows];
      changedRows[index].data[field] = value;
      return {
        ...state,
        rows: changedRows,
      };
    }

    case 'CELL_EDITING_STARTED':
      return {
        ...state,
        editedCell: action.payload,
      };

    case 'CELL_EDITING_CANCELED':
      return {
        ...state,
        editedCell: null,
      };

    case 'CELL_EDITING_FINISHED': {
      const { rowId, field, value } = action.payload;
      const index = state.rows.findIndex(row => row.id === rowId);
      const changedRows = [...state.rows];
      changedRows[index].data[field] = value;
      return {
        ...state,
        rows: changedRows,
        editedCell: null,
      };
    }

    default:
      return state;
  }
};

const getInitialState = ({ id, initialSort, initialPageSize }) => {
  const savedSort = id && JSON.parse(localStorage.getItem(`${id}.sort`));

  return {
    headerGroups: [],
    columns: [],
    rows: [],
    sort: savedSort || initialSort,
    page: 1,
    pageSize: initialPageSize,
    editedCell: null,
  };
};

const useGridState = ({
  id,
  colDefs,
  rowDefs,
  initialSort,
  initialPageSize,
  getRowId,
  onCellChange,
  onCellEdited,
  onSortChange,
  onPageChange,
  onPageSizeChange,
}) => {
  const scrollRef = useRef();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({ id, initialSort, initialPageSize }),
  );

  useEffect(() => {
    const { headerGroups, columns } = prepareColumns(colDefs);
    dispatch({ type: 'COLUMNS_CHANGED', payload: { headerGroups, columns } });
  }, [colDefs]);

  useEffect(() => {
    const rows = prepareRows({
      rowDefs,
      getRowId,
      sort: state.sort,
      columns: state.columns,
      serverSideSort: onSortChange,
    });
    dispatch({ type: 'ROWS_CHANGED', payload: rows });
  }, [rowDefs, getRowId, state.sort, state.columns, onSortChange]);

  const changeSort = ({ field }) => {
    const order = getSortOrder(field, state.sort.field, state.sort.order);
    id && localStorage.setItem(`${id}.sort`, JSON.stringify({ order, field }));
    dispatch({
      type: 'SORT_CHANGED',
      payload: { order, field },
    });
    onSortChange && onSortChange({ order, field });
  };

  const changePage = page => {
    dispatch({ type: 'PAGE_CHANGED', payload: page });
    onPageChange && onPageChange(page);
  };

  const changePageSize = pageSize => {
    dispatch({ type: 'PAGE_SIZE_CHANGED', payload: pageSize });
    onPageSizeChange && onPageSizeChange(pageSize);
  };

  const updateCell = ({ rowId, field, value, rowData }) => {
    const prevValue = rowData[field];
    dispatch({ type: 'CELL_CHANGED', payload: { rowId, field, value } });
    onCellChange && prevValue !== value && onCellChange({ rowId, field, value, rowData });
  };

  const startCellEditing = ({ rowId, field }) => {
    dispatch({ type: 'CELL_EDITING_STARTED', payload: { rowId, field } });
  };

  const cancelCellEditing = () => {
    dispatch({ type: 'CELL_EDITING_CANCELED' });
  };

  const finishCellEditing = ({ rowId, field, value, rowData }) => {
    const prevValue = rowData[field];
    dispatch({ type: 'CELL_EDITING_FINISHED', payload: { rowId, field, value } });
    onCellEdited && prevValue !== value && onCellEdited({ rowId, field, value, rowData });
  };

  return {
    ...state,
    scrollRef,
    changeSort,
    changePage,
    changePageSize,
    updateCell,
    startCellEditing,
    cancelCellEditing,
    finishCellEditing,
  };
};

export default useGridState;
