import React from 'react';
import { Form, Spinner, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Close as CloseIcon } from 'components/icons';
import useDeleteProduct from './useDeleteProduct';

const ProductDeleteModal = ({ supplyId, productId, productName, maxQty, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const { deleteProduct, isDeleting } = useDeleteProduct({ onSuccess });

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      supplyId,
      productId,
      qty: '',
    },
    validateOnChange: false,
    validate: formValues => {
      if (formValues.qty > maxQty) {
        return { qty: `Значение должнно быть меньше либо равно ${maxQty}` };
      }
      if (formValues.qty < 1) {
        return { qty: 'Значение должно быть больше либо равно 1' };
      }
      return null;
    },
    onSubmit: values => {
      if (supplyId) {
        deleteProduct(values);
      } else {
        onSuccess(values);
      }
    },
  });

  return (
    <Modal show centered size="md" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{t('supply:productDeleteModal.title')}</Modal.Title>
        <CloseIcon size={20} onClick={onClose} />
      </Modal.Header>
      {isDeleting ? (
        <Modal.Body>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="productName">
              <Form.Label>{t('supply:productDeleteModal.product')}</Form.Label>
              <Form.Control disabled defaultValue={productName} />
            </Form.Group>
            <Form.Group controlId="productQty">
              <Form.Label>{t('supply:productDeleteModal.amount')}</Form.Label>
              <Form.Control
                type="number"
                name="qty"
                placeholder={t('supply:productDeleteModal.amountPlaceholder')}
                value={values.qty}
                isInvalid={errors.qty}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.qty}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              {t('shared:buttons.cancel')}
            </Button>
            <Button type="submit">{t('shared:buttons.delete')}</Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default ProductDeleteModal;
