import React from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { DarkstoresSelect } from 'components';
import { useModalState } from 'hooks';
import { DataGrid, DatePicker, Dropzone } from 'components/elements';
import { Add as AddIcon } from 'components/icons';
import { useSettingsContext } from 'contexts/SettingsContext';
import { formatDate } from 'utils/formatters';
import { ProductAddModal } from './ProductAddModal';
import { ProductDeleteModal } from './ProductDeleteModal';
import exportSupply from './exportSupply';
import recalculateProduct from './recalculateProduct';
import useCalculation from './useCalculation';
import useColumns from './useColumns';
import useOnCellEdited from './useOnCellEdited';
import useRowMenu from './useRowMenu';

const TRANSLATION_PREFIX = 'supply:form';

const SupplyForm = ({ isSubmiting, initialValues, allowedDocumentsEdit, onSubmit, onExport }) => {
  const { t } = useTranslation();

  const { currency } = useSettingsContext();

  const modalState = useModalState();

  const { values, handleSubmit, setFieldValue, submitForm } = useFormik({
    initialValues,
    validateOnChange: !!initialValues.id,
    onSubmit,
  });

  useCalculation({ isEnabled: !initialValues.id, values, setFieldValue });

  const columns = useColumns({ t, status: initialValues.status, currency });
  const rowMenu = useRowMenu({ t, id: initialValues.id, modalState });
  const onCellEdited = useOnCellEdited({
    supplyId: initialValues.id,
    products: values.products,
    setFieldValue,
    submitForm,
  });

  if (isSubmiting) {
    return <Spinner animation="border" variant="primary" />;
  }

  const {
    isCheck,
    dateCreate,
    date,
    providerId,
    providerName,
    darkstoreId,
    darkstoreName,
    darkstoreAddress,
    darkstorePhone,
    sumWithoutVat,
    sum,
    consignmentNote,
    products,
    bill,
    paymentOrder,
    waybill,
  } = values;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.requestDate`)}</Col>
              <Col>{formatDate(dateCreate, 'DD.MM.YYYY')}</Col>
            </Row>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.supplyDate`)}</Col>
              <Col>
                <DatePicker
                  value={date}
                  onChange={async newDate => {
                    await setFieldValue('date', newDate);
                    initialValues.id && submitForm();
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.provider`)}</Col>
              <Col>{providerName}</Col>
            </Row>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.darkstore`)}</Col>
              <Col>
                {initialValues.id ? (
                  darkstoreName
                ) : (
                  <DarkstoresSelect
                    value={{ id: darkstoreId, name: darkstoreName }}
                    onChange={async newDarkstore => {
                      await setFieldValue('darkstoreId', newDarkstore.id);
                      await setFieldValue('darkstoreName', newDarkstore.name);
                      initialValues.id && submitForm();
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.sumWithoutVat`)}</Col>
              <Col>
                {sumWithoutVat} {currency}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.sumWithVat`)}</Col>
              <Col>
                {sum} {currency}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="font-weight-bold">{t(`${TRANSLATION_PREFIX}.cnn`)}</Col>
              <Col>{consignmentNote}</Col>
            </Row>
          </Col>
        </Row>

        {initialValues.status !== 3 && (
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Form.Group controlId="isCheck">
              <Form.Check
                type="switch"
                label={t(`${TRANSLATION_PREFIX}.checked`)}
                checked={!!isCheck}
                onChange={() => {
                  setFieldValue('isCheck', isCheck ? 0 : 1);
                  initialValues.id && submitForm();
                }}
              />
            </Form.Group>
            <Button
              variant="outline-primary"
              onClick={() => {
                modalState.set({
                  isOpened: true,
                  key: 'addProduct',
                  supplyId: initialValues.id,
                  providerId: providerId,
                  addedProductIds: products.map(product => product.productId),
                });
              }}
            >
              <AddIcon color="inherit" size={14} />
              {t(`${TRANSLATION_PREFIX}.addProduct`)}
            </Button>
          </div>
        )}

        <DataGrid
          className="mb-3"
          columns={columns}
          rows={products}
          height={550}
          rowMenu={rowMenu}
          getRowId={row => row.productId}
          getRowClassName={({ rowData }) => rowData.isFinishSelling && 'row-red-light'}
          onCellEdited={onCellEdited}
        />

        {allowedDocumentsEdit && (
          <Row>
            <Col>
              <Form.Label>{t(`${TRANSLATION_PREFIX}.invoice`)}</Form.Label>
              <Dropzone
                accept=".pdf, .doc, .docx"
                title={t(`${TRANSLATION_PREFIX}.invoicePlaceholder`)}
                maxSize={5242880}
                type="doc"
                value={bill}
                onUpload={async newBill => {
                  await setFieldValue('bill', newBill);
                  initialValues.id && submitForm();
                }}
              />
            </Col>
            <Col>
              <Form.Label>{t(`${TRANSLATION_PREFIX}.paymentOrder`)}</Form.Label>
              <Dropzone
                accept=".pdf, .doc, .docx"
                title={t(`${TRANSLATION_PREFIX}.paymentOrderPlaceholder`)}
                maxSize={5242880}
                type="doc"
                value={paymentOrder}
                onUpload={async newPaymentOrder => {
                  await setFieldValue('paymentOrder', newPaymentOrder);
                  initialValues.id && submitForm();
                }}
              />
            </Col>
            <Col>
              <Form.Label>{t(`${TRANSLATION_PREFIX}.consignmentNote`)}</Form.Label>
              <Dropzone
                accept=".pdf, .doc, .docx"
                title={t(`${TRANSLATION_PREFIX}.consignmentNotePlaceholder`)}
                maxSize={5242880}
                type="doc"
                value={waybill}
                onUpload={async newWaybill => {
                  await setFieldValue('waybill', newWaybill);
                  initialValues.id && submitForm();
                }}
              />
            </Col>
          </Row>
        )}

        <div className="d-flex justify-content-end mt-3">
          {initialValues.id ? (
            <Button
              variant="outline-primary"
              onClick={() => {
                exportSupply({
                  id: initialValues.id,
                  darkstoreName,
                  products,
                  sum,
                  darkstoreAddress,
                  darkstorePhone,
                  providerName,
                  date,
                  t,
                });
                onExport(initialValues);
              }}
            >
              {t(`${TRANSLATION_PREFIX}.export`)}
            </Button>
          ) : (
            <Button type="submit">{t('shared:buttons.add')}</Button>
          )}
        </div>
      </Form>

      {modalState.isOpened && modalState.key === 'addProduct' && (
        <ProductAddModal
          {...modalState}
          onClose={modalState.close}
          onSuccess={async addedProduct => {
            if (addedProduct) {
              await setFieldValue('products', [...products, addedProduct]);
              initialValues.id && submitForm();
            }
            modalState.close();
          }}
        />
      )}

      {modalState.isOpened && modalState.key === 'deleteProduct' && (
        <ProductDeleteModal
          {...modalState}
          onClose={modalState.close}
          onSuccess={async deletedProduct => {
            if (deletedProduct) {
              const index = products.findIndex(
                product => product.productId === deletedProduct.productId,
              );
              if (products[index].qty === deletedProduct.qty) {
                await setFieldValue(
                  'products',
                  products.filter(product => product.productId !== deletedProduct.productId),
                );
              } else {
                const newProducts = [...products];
                const product = {
                  ...newProducts[index],
                  qty: newProducts[index].qty - deletedProduct.qty,
                };
                newProducts[index] = recalculateProduct(product);
                await setFieldValue('products', newProducts);
              }
              initialValues.id && submitForm();
            }
            modalState.close();
          }}
        />
      )}
    </>
  );
};

export default SupplyForm;
