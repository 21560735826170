import { useCallback, useState } from 'react';

const getInitialState = (key, initialValue) => {
  const localStorageValue = localStorage.getItem(key);
  if (localStorageValue) {
    return JSON.parse(localStorageValue);
  }
  return initialValue;
};

const useLocalStorage = (key, initialValue) => {
  const [state, set] = useState(getInitialState(key, initialValue));

  const setState = useCallback(
    patch => {
      localStorage.setItem(key, JSON.stringify(patch));
      set(patch);
    },
    [key, set],
  );

  return [state, setState];
};

export default useLocalStorage;
