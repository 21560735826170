import React from 'react';
import { SvgIcon } from '../elements';

const Supplies = props => {
  return (
    <SvgIcon {...props}>
      <path d="M17.157 0h-16.454c-0.388 0-0.703 0.315-0.703 0.703v22.593c0 0.388 0.315 0.703 0.703 0.703h16.454c0.388 0 0.703-0.315 0.703-0.703v-22.593c0-0.388-0.315-0.703-0.703-0.703zM7.382 13.764l-2.136 2.136c-0.132 0.132-0.311 0.206-0.497 0.206s-0.365-0.074-0.497-0.206l-1.007-1.007c-0.275-0.275-0.275-0.72 0-0.994s0.72-0.275 0.994 0l0.509 0.509 1.639-1.639c0.275-0.275 0.72-0.275 0.994 0s0.275 0.72 0 0.994zM7.382 9.499l-2.136 2.136c-0.132 0.132-0.311 0.206-0.497 0.206s-0.365-0.074-0.497-0.206l-1.007-1.007c-0.275-0.275-0.275-0.72 0-0.994s0.72-0.275 0.994 0l0.509 0.509 1.639-1.639c0.275-0.275 0.72-0.275 0.994 0s0.275 0.72 0 0.994zM7.382 5.233l-2.136 2.136c-0.132 0.132-0.311 0.206-0.497 0.206s-0.365-0.074-0.497-0.206l-1.007-1.007c-0.275-0.275-0.275-0.72 0-0.994s0.72-0.275 0.994 0l0.509 0.509 1.639-1.639c0.275-0.275 0.72-0.275 0.994 0s0.275 0.72 0 0.994zM14.083 19.696h-4.465c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h4.465c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM14.083 15.267h-4.465c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h4.465c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM14.083 11.001h-4.465c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h4.465c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703zM14.083 6.735h-4.465c-0.388 0-0.703-0.315-0.703-0.703s0.315-0.703 0.703-0.703h4.465c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703z"></path>
      <path d="M24 2.387c0-1.316-1.071-2.386-2.386-2.386s-2.386 1.071-2.386 2.386v2.062h4.773v-2.062z"></path>
      <path d="M19.268 19.39c-0.063 0.174-0.055 0.365 0.022 0.533l1.683 3.668c0.115 0.25 0.364 0.41 0.639 0.41s0.524-0.16 0.639-0.41l1.683-3.668c0.077-0.168 0.085-0.359 0.022-0.533l-0.432-1.194h-3.825l-0.432 1.194z"></path>
      <path d="M24 16.087v-10.232h-4.773v10.232c0 0.388 0.315 0.703 0.703 0.703h3.367c0.388 0 0.703-0.315 0.703-0.703z"></path>
    </SvgIcon>
  );
};

export default Supplies;
