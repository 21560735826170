import React from 'react';
import { SvgIcon } from '../elements';

const Copy = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.248 0h-10.083c-1.465 0-2.656 1.192-2.656 2.656v0.326h-1.757c-1.465 0-2.656 1.192-2.656 2.656v15.705c0 1.465 1.192 2.656 2.656 2.656h10.083c1.465 0 2.656-1.192 2.656-2.656v-0.326h1.757c1.465 0 2.656-1.192 2.656-2.656v-15.705c0-1.465-1.191-2.656-2.656-2.656zM15.778 21.344c0 0.52-0.423 0.943-0.943 0.943h-10.083c-0.52 0-0.943-0.423-0.943-0.943v-15.705c0-0.52 0.423-0.943 0.943-0.943h10.083c0.52 0 0.943 0.423 0.943 0.943v15.705h0zM20.191 18.361c0 0.52-0.423 0.943-0.943 0.943h-1.757v-13.666c0-1.465-1.192-2.656-2.656-2.656h-6.613v-0.326c0-0.52 0.423-0.943 0.943-0.943h10.083c0.52 0 0.943 0.423 0.943 0.943v15.705z"></path>
    </SvgIcon>
  );
};

export default Copy;
