import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/elements';

const LANGUAGE_RU = { key: 'ru', label: 'Русский' };
const LANGUAGE_PL = { key: 'pl', label: 'Polskie' };

const InterfaceLanguageChanger = () => {
  const { i18n } = useTranslation();

  const options = [LANGUAGE_RU, LANGUAGE_PL];
  const selectedOption = options.find(option => option.key === i18n.language);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Select
      containerStyles={{
        flex: 'none',
        width: 'auto',
      }}
      options={options}
      value={selectedOption}
      getOptionValue={option => option.key}
      onChange={option => i18n.changeLanguage(option.key)}
    />
  );
};

export default InterfaceLanguageChanger;
