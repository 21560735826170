import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkCellRender, TooltipCellRender } from 'components/cell-renders';

const useColumns = ({ darkstoreId, darkstores }) => {
  const { t } = useTranslation('writeOffs', { keyPrefix: 'columns' });

  return useMemo(() => {
    const columns = [
      {
        header: t('writeOffDate'),
        field: 'date',
        width: 175,
      },
      {
        header: t('writeOffTime'),
        field: 'time',
        width: 175,
      },
      {
        header: t('product'),
        field: 'name',
        width: 350,
        renderCell: ({ value, rowData }) => (
          <TooltipCellRender>
            <LinkCellRender to={`/assortiment/products/${rowData.productId}`} value={value} />
          </TooltipCellRender>
        ),
      },
      {
        header: t('qty'),
        field: 'qty',
        valueFormatter: params => {
          switch (params.rowData.type) {
            case 'plus':
              return `+${params.value}`;
            case 'minus':
              return `-${params.value}`;
            default:
              return params.value;
          }
        },
      },
      {
        header: t('reason'),
        field: 'reason',
        width: 200,
      },
      {
        header: t('employee'),
        field: 'employee',
        width: 200,
      },
      {
        header: t('purchasePrice'),
        field: 'pricePurchases',
      },
      {
        header: t('salePrice'),
        field: 'price',
        width: 175,
      },
      {
        header: t('vat'),
        field: 'vat',
      },
    ];

    if (darkstoreId === 'all') {
      columns.splice(2, 0, {
        header: t('darkstore'),
        field: 'darkstoreId',
        width: 175,
        valueGetter: ({ rowData }) => {
          const darkstore = darkstores.find(d => d.id === rowData.darkstoreId);
          if (!darkstore) {
            return '-';
          }
          return darkstore.name;
        },
      });
    }
    return columns;
  }, [t, darkstoreId, darkstores]);
};

export default useColumns;
