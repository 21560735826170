import React from 'react';
import { SvgIcon } from '../elements';

const Close = props => {
  return (
    <SvgIcon {...props}>
      <path d="M24 2.667l-2.417-2.354-9.583 9.333-9.583-9.333-2.417 2.354 9.583 9.333-9.583 9.333 2.417 2.354 9.583-9.333 9.583 9.333 2.417-2.354-9.583-9.333 9.583-9.333z"></path>
    </SvgIcon>
  );
};

export default Close;
