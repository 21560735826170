import React, { useMemo } from 'react';
import { useOrderStatuses } from 'hooks/constants';
import { Badge, Select } from '../elements';

const OrderStatus = ({ disabled, orderId, statusValue, onChange }) => {
  const { orderStatuses } = useOrderStatuses();

  const value = useMemo(
    () => orderStatuses.find(status => status.value === statusValue),
    [orderStatuses, statusValue],
  );

  if (disabled || value.value === 'CANCELED') {
    return <Badge background={value.background}>{value.label}</Badge>;
  }

  return (
    <Badge background={value.background}>
      <Select
        isBordered={false}
        size="small"
        options={orderStatuses}
        value={value}
        onChange={newStatus => {
          onChange({ orderId, status: newStatus.value }, { orderId, status: statusValue });
        }}
      />
    </Badge>
  );
};

export default OrderStatus;
