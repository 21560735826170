import React from 'react';
import { Reload as ReloadIcon } from '../../../icons';
import { GridPageSize } from '../GridPageSize';
import styles from './GridToolbar.module.scss';

const GridToolbar = ({
  scrollRef,
  title,
  pageSizeOptions,
  rowsCount,
  pagesCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onReload,
  onLoadMore,
}) => {
  if (!title && !onReload && pagesCount < 2) {
    return null;
  }

  return (
    <div className={styles.root}>
      {title && <h2>{title}</h2>}

      {onReload && (
        <ReloadIcon
          size={20}
          onClick={() => {
            onPageChange(1);
            scrollRef.current.scrollTop = 0;
            onReload({ page, pageSize });
          }}
        />
      )}

      <GridPageSize
        pageSizeOptions={pageSizeOptions}
        rowsCount={rowsCount}
        page={page}
        pagesCount={pagesCount}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onLoadMore={onLoadMore}
      />
    </div>
  );
};

export default GridToolbar;
