import React, { useState, useCallback, useEffect } from 'react';
import styles from './GridWindow.module.scss';

const GridWindow = ({
  children,
  scrollRef,
  width,
  height,
  isLoading,
  isVirtualized,
  rows,
  rowHeight,
  onLoadMore,
}) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const rowsHeight = rows.length * rowHeight;
    if (onLoadMore && rowsHeight > 0 && rowsHeight < height) {
      onLoadMore();
    }
  }, [rows.length, height, rowHeight, onLoadMore]);

  const onScroll = useCallback(
    ({ target: { scrollHeight, scrollTop, clientHeight } }) => {
      const offset = scrollHeight - clientHeight - scrollTop;

      if (isVirtualized) {
        setScrollTop(scrollTop);
      }

      if (onLoadMore && !isLoading && offset <= rowHeight) {
        onLoadMore();
      }
    },
    [isLoading, isVirtualized, rowHeight, onLoadMore],
  );

  return (
    <div ref={scrollRef} className={styles.root} style={{ width, height }} onScroll={onScroll}>
      {children(scrollTop)}
    </div>
  );
};

export default GridWindow;
