import React, { lazy, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

export default importFunc => {
  const LazyComponent = lazy(importFunc);
  return props => (
    <Suspense fallback={<Spinner animation="border" variant="primary" />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
