import { useQuery } from 'react-query';
import api from 'utils/api';

// import accesses from './accesses';

const useUser = ({ enabled, onSuccess, onError }) => {
  const { isLoading, data } = useQuery({
    queryKey: 'user',
    queryFn: async () => {
      const user = await api.get('admin/user');
      // const darkstores = user.darkstores.filter(d => ![3, 6].includes(d));
      delete user.acl.assortment;
      if (user.partnerId === 0) {
        ['providers', 'supplies', 'write-offs'].forEach(key => delete user.acl[key]);
      }
      // const access = accesses.find(access => access.email === user.email) ?? {};
      return {
        ...user,
        // darkstores,
        // canCopyProductsTo: access.canCopyProductsTo ?? [],
      };
    },
    enabled,
    onSuccess,
    onError,
  });

  return { isLoading, user: data };
};

export default useUser;
