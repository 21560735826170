import React from 'react';
import { SvgIcon } from '../elements';

const Edit = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.714 12c-0.473 0-0.857 0.384-0.857 0.857v8.571c0 0.474-0.384 0.857-0.857 0.857h-15.428c-0.473 0-0.857-0.384-0.857-0.857v-17.143c0-0.473 0.384-0.857 0.857-0.857h10.286c0.473 0 0.857-0.384 0.857-0.857s-0.384-0.857-0.857-0.857h-10.286c-1.42 0-2.571 1.151-2.571 2.571v17.143c0 1.42 1.151 2.572 2.571 2.572h15.428c1.42 0 2.571-1.151 2.571-2.572v-8.571c0-0.473-0.384-0.857-0.857-0.857z"></path>
      <path d="M23.039 0.961c-0.616-0.616-1.45-0.961-2.321-0.961-0.871-0.003-1.707 0.344-2.32 0.962l-11.29 11.289c-0.094 0.094-0.164 0.209-0.207 0.335l-1.714 5.143c-0.15 0.449 0.093 0.934 0.542 1.084 0.087 0.029 0.178 0.044 0.27 0.044s0.183-0.015 0.271-0.044l5.143-1.714c0.126-0.042 0.241-0.113 0.335-0.207l11.29-11.29c1.281-1.281 1.282-3.359 0-4.64z"></path>
    </SvgIcon>
  );
};

export default Edit;
