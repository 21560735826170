import React from 'react';
import clsx from 'clsx';
import styles from './Checkbox.module.scss';

const Checkbox = ({ className, isChecked = false, onChange }) => {
  return (
    <span className={clsx(styles.root, className)}>
      <input className={styles.input} type="checkbox" checked={isChecked} onChange={() => {}} />
      <span className={styles.checkmark} onClick={() => onChange && onChange(!isChecked)} />
    </span>
  );
};

export default Checkbox;
