import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const parseSearchQuery = searchStr => {
  let searchObj = Object.fromEntries(new URLSearchParams(searchStr).entries());
  for (let key in searchObj) {
    const value = searchObj[key];
    try {
      searchObj[key] = JSON.parse(value);
    } catch (err) {
      // silent
    }
  }
  return searchObj;
};

const stringifySearchQuery = searchObj => {
  if (searchObj) {
    Object.keys(searchObj).forEach(key => {
      const val = searchObj[key];
      if (typeof val === 'undefined' || val === undefined) {
        delete searchObj[key];
      } else if (val && typeof val === 'object' && val !== null) {
        try {
          searchObj[key] = JSON.stringify(val);
        } catch (err) {
          // silent
        }
      }
    });
  }
  const searchStr = new URLSearchParams(searchObj).toString();
  return searchStr ? `?${searchStr}` : '';
};

const useSearch = initialSearch => {
  const { push, replace } = useHistory();
  const { search } = useLocation();

  const initialSearchRef = useRef(initialSearch);

  const searchParams = useMemo(() => {
    const parsed = parseSearchQuery(search);
    return {
      ...initialSearchRef.current,
      ...parsed,
    };
  }, [search]);

  const setSearchParams = useCallback(
    (changes, { replaced = true, mergeParams = true } = {}) => {
      const str = mergeParams
        ? stringifySearchQuery({
            ...searchParams,
            ...changes,
          })
        : stringifySearchQuery(changes);

      replaced ? replace(str) : push(str);
    },
    [searchParams, push, replace],
  );

  return [searchParams, setSearchParams];
};

export default useSearch;
