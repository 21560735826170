import { useQueryClient, useMutation } from 'react-query';
import api from 'utils/api';
import message from 'utils/message';

const addProduct = ({ supplyId, productId, qty }) => {
  return api.post('admin/supplies/product', { suppliesId: supplyId, productId, qty });
};

const useAddProduct = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(addProduct, {
    onSuccess: (_, { supplyId }) => {
      queryClient.resetQueries(`admin/supplies/${supplyId}`);
      message('Изменения сохранены', { type: 'success', withIcon: true });
      onSuccess();
    },
    onError: error => message(error.message, { type: 'error' }),
  });

  return {
    addProduct: mutate,
    isAdding: isLoading,
  };
};

export default useAddProduct;
