import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    supportedLngs: ['ru', 'pl'],
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'assortiment',
      'blacklist',
      'client',
      'clients',
      'darkstoreZone',
      'employees',
      'info',
      'layout',
      'marketing',
      'newRequest',
      'order',
      'orders',
      'product',
      'products',
      'providers',
      'registry',
      'reports',
      'shared',
      'supplies',
      'supply',
      'users',
      'writeOffs',
    ],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
