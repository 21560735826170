import React from 'react';

const Badge = ({ background = '#CACDD9', children }) => {
  return (
    <span
      style={{
        background,
        display: 'inline-block',
        borderRadius: '4px',
        padding: '0 6px',
      }}
    >
      {children}
    </span>
  );
};

export default Badge;
