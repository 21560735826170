import { useQuery } from 'react-query';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

const getCategories = ({ darkstoreId }) => {
  return api.get(`admin/assortiment/categories/${darkstoreId}`);
};

const useCategories = ({ darkstoreId: localDarkstoreId = null, enabled = true } = {}) => {
  const { darkstoreId: globalDarkstoreId, language } = useSettingsContext();

  const darkstoreId = localDarkstoreId || globalDarkstoreId;

  const { isLoading, data = [] } = useQuery(
    ['categories', { darkstoreId, language }],
    () => getCategories({ darkstoreId }),
    { enabled },
  );

  return { isLoading, categories: data };
};

export default useCategories;
