import React from 'react';
import { Form } from 'react-bootstrap';

const SwitchCellRender = ({ disabled, rowId, rowData, field, value, updateCell }) => {
  return (
    <Form.Check
      disabled={disabled}
      type="switch"
      label=""
      id={`${rowId}-${field}`}
      checked={value || value.toString() === '1'}
      onChange={() => {
        updateCell({ rowId, rowData, field, value: value.toString() === '0' ? 1 : 0 });
      }}
    />
  );
};

export default React.memo(
  SwitchCellRender,
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);
