import { useQueryClient, useMutation } from 'react-query';
import api from 'utils/api';
import message from 'utils/message';

const deleteProduct = ({ supplyId, productId, qty }) => {
  return api.delete('admin/supplies/product', {
    suppliesId: supplyId,
    productId,
    qty,
    reason: 'Удалено менеджером',
  });
};

const useDeleteProduct = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteProduct, {
    onSuccess: (_, { supplyId }) => {
      queryClient.resetQueries(`admin/supplies/${supplyId}`);
      message('Изменения сохранены', { type: 'success', withIcon: true });
      onSuccess();
    },
    onError: error => message(error.message, { type: 'error' }),
  });

  return {
    deleteProduct: mutate,
    isDeleting: isLoading,
  };
};

export default useDeleteProduct;
