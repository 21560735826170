import { useCallback } from 'react';
import recalculateProduct from './recalculateProduct';

const useOnCellEdited = ({ supplyId, products, setFieldValue, submitForm }) => {
  const onCellEdited = useCallback(
    async ({ rowId, field, value }) => {
      const newProducts = [...products];
      const index = newProducts.findIndex(product => product.productId === rowId);
      const product = {
        ...newProducts[index],
        [field]: value,
      };
      newProducts[index] = recalculateProduct(product);
      await setFieldValue('products', newProducts);
      supplyId && submitForm();
    },
    [supplyId, products, setFieldValue, submitForm],
  );

  return onCellEdited;
};

export default useOnCellEdited;
