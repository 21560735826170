import React from 'react';
import { SvgIcon } from '../elements';

const Table = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0.933 2.667h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M14.267 2.667h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M0.933 15.843h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M14.267 15.843h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M0.933 9.255h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
      <path d="M14.267 9.255h8.8c0.515 0 0.933 0.418 0.933 0.933v3.624c0 0.515-0.418 0.933-0.933 0.933h-8.8c-0.515 0-0.933-0.418-0.933-0.933v-3.624c0-0.515 0.418-0.933 0.933-0.933z"></path>
    </SvgIcon>
  );
};

export default Table;
