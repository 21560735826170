import { makeXLSXsheet, exportToXLSX } from 'utils/helpers';

export const exportWriteOffs = ({ darkstoreId, darkstores, columns, writeOffs }) => {
  if (darkstoreId !== 'all') {
    exportToXLSX([makeXLSXsheet(columns, writeOffs)], 'Списания');
  } else {
    const sheets = darkstores.map(d => {
      const data = writeOffs.filter(w => w.darkstoreId === d.id);
      return makeXLSXsheet(
        columns.filter(c => c.field !== 'darkstoreId'),
        data,
        d.name,
      );
    });

    const sumColumns = [columns[3], columns[4], columns[5], columns[7], columns[8], columns[9]];
    const sum = writeOffs.reduce((acc, w) => {
      const index = acc.findIndex(s => s.productId === w.productId);
      if (index === -1) {
        acc.push(w);
      } else {
        acc[index].qty += w.qty;
      }
      return acc;
    }, []);
    const sumSheet = makeXLSXsheet(sumColumns, sum, 'Все');

    exportToXLSX([...sheets, sumSheet], 'Возвраты');
  }
};
