import { round } from 'utils/helpers';

const recalculateProduct = product => {
  const price = round(
    product.priceWithoutVat * (1 + product.vat / 100) * (1 + product.extraCharge / 100),
  );
  const vatValue = round(product.priceWithoutVat * (product.vat / 100));
  const vatSum = round(vatValue * product.qty);
  const sumWithoutVat = round(product.priceWithoutVat * product.qty);
  return {
    ...product,
    price,
    vatValue,
    vatSum,
    sumWithoutVat,
  };
};

export default recalculateProduct;
