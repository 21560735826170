import React from 'react';
import { Star as StarIcon } from 'components/icons';

const RatingCellRender = ({ value }) => {
  if (!['string', 'number'].includes(typeof value)) {
    return '-';
  }

  return (
    <>
      {value}
      <StarIcon className="ml-1 mb-1" color="primary" size={14} />
    </>
  );
};

export default React.memo(
  RatingCellRender,
  (prevProps, nextProps) => prevProps.value === nextProps.value,
);
