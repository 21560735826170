import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';
import { capitalize } from 'utils/helpers';
import { ChevronDown as ChevronDownIcon } from '../../../icons';
import styles from './SelectControl.module.scss';

const getCorrectInputValue = ({
  isSearchable,
  isMulti,
  isInputFocused,
  isMenuOpen,
  inputValue,
  selectedItems,
}) => {
  if (isMulti && isSearchable && (isInputFocused || isMenuOpen)) {
    return inputValue;
  }

  if (isSearchable && (isInputFocused || isMenuOpen)) {
    return inputValue;
  }

  return selectedItems.map(item => item.label).join(', ');
};

const SelectControl = ({
  isMenuOpen,
  isSearchable,
  isMulti,
  isLoading,
  isInvalid,
  isBordered,
  disabled,
  size,
  placeholder,
  selectedItems,
  getComboboxProps,
  getInputProps,
  getDropdownProps,
  getToggleButtonProps,
  openMenu,
  setInputValue,
  clearInputValue,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const inputProps = getInputProps(
    getDropdownProps({
      preventKeyAction: isMenuOpen,
      placeholder,
      onFocus: () => {
        setIsInputFocused(true);
        if (isMulti) {
          clearInputValue();
        } else {
          setInputValue(selectedItems.map(item => item.label).join(', '));
        }
      },
      onBlur: () => setIsInputFocused(false),
    }),
  );

  const inputValue = getCorrectInputValue({
    isSearchable,
    isMulti,
    isInputFocused,
    isMenuOpen,
    inputValue: inputProps.value,
    selectedItems,
  });

  return (
    <div
      {...getComboboxProps()}
      //get classnames from all items ?_?
      className={clsx(styles.root, selectedItems[0]?.className)}
      onClick={() => {
        if (!isMenuOpen && !disabled) {
          openMenu();
        }
      }}
    >
      <input
        {...inputProps}
        className={clsx(
          styles.input,
          styles[`inputSize${capitalize(size)}`],
          isInvalid && styles.inputInvalid,
          isLoading && styles.inputLoading,
          !isBordered && styles.inputBorderless,
          (isInputFocused || isMenuOpen) && styles.inputFocused,
        )}
        disabled={disabled}
        readOnly={!isSearchable}
        autoComplete="off"
        value={inputValue}
      />

      <button
        {...getToggleButtonProps()}
        className={styles.toggleButton}
        type="button"
        aria-label="toggle menu"
        disabled={disabled}
      >
        {isLoading ? (
          <Spinner animation="border" variant="primary" size="sm" />
        ) : (
          <ChevronDownIcon size={12} />
        )}
      </button>
    </div>
  );
};

export default SelectControl;
