import { useQuery } from 'react-query';
import { useSettingsContext } from 'contexts/SettingsContext';
import api from 'utils/api';

const getProducts = async ({ providerId, darkstoreId }) => {
  const { products } = await api.get(`admin/providers/${providerId}/${darkstoreId}`);

  return products;
};

const useProducts = ({ providerId }) => {
  const { darkstoreId } = useSettingsContext();

  const { isLoading, data = [] } = useQuery(
    ['provider/products', { providerId, darkstoreId }],
    () => getProducts({ providerId, darkstoreId }),
  );

  return {
    isLoading,
    products: data,
  };
};

export default useProducts;
