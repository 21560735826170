import React, { useReducer } from 'react';
import { Spinner } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import logger from 'utils/logger';
import AuthContext from './AuthContext';
import useUser from './useUser';

const reducer = (state, action) => {
  switch (action.type) {
    case 'USER_LOADED':
      return { ...state, isAuthenticated: true };
    case 'SAVE_TOKEN':
      return { ...state, hasToken: true };
    case 'CLEAR':
      return { hasToken: false, isAuthenticated: false };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const { replace } = useHistory();

  const queryClient = useQueryClient();

  const [state, dispatch] = useReducer(logger(reducer), {
    hasToken: !!localStorage.getItem('token'),
    isAuthenticated: false,
  });

  const login = token => {
    localStorage.setItem('token', token);
    dispatch({ type: 'SAVE_TOKEN' });
    replace('/');
  };

  const logout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'CLEAR' });
    replace('/login');
    queryClient.removeQueries('user');
  };

  const { isLoading, user } = useUser({
    enabled: state.hasToken,
    onSuccess: () => dispatch({ type: 'USER_LOADED' }),
    onError: error => console.error(error),
  });

  if (isLoading) {
    return <Spinner animation="border" variant="primary" className="my-3" />;
  }

  const checkPermission = pathStr => {
    const pathArr = pathStr.split('.');
    let result = user.acl;
    for (let index = 0; index < pathArr.length; index++) {
      if (typeof result[pathArr[index]] === 'undefined') {
        return undefined;
      }
      result = result[pathArr[index]];
    }
    return result;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user,
        login,
        logout,
        checkPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
