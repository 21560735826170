import React from 'react';
import { SvgIcon } from '../elements';

const Assortiment = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.25 24h-4c-0.414 0-0.75-0.336-0.75-0.75v-1.25h-3v1.25c0 0.414-0.336 0.75-0.75 0.75h-5.5c-0.414 0-0.75-0.336-0.75-0.75v-1.25h-3v1.25c0 0.414-0.336 0.75-0.75 0.75h-4c-0.414 0-0.75-0.336-0.75-0.75v-3.25h24v3.25c0 0.414-0.336 0.75-0.75 0.75z"></path>
      <path d="M16 0h-3v2c0 0.552-0.448 1-1 1s-1-0.448-1-1v-2h-3c-0.552 0-1 0.448-1 1v6c0 0.552 0.448 1 1 1h8c0.552 0 1-0.448 1-1v-6c0-0.552-0.448-1-1-1z"></path>
      <path d="M10 10.001h-3v2c0 0.552-0.448 1-1 1s-1-0.448-1-1v-2h-3c-0.552 0-1 0.448-1 1v6c0 0.552 0.448 1 1 1h8c0.552 0 1-0.448 1-1v-6c0-0.552-0.448-1-1-1z"></path>
      <path d="M22 10.001h-3v2c0 0.552-0.448 1-1 1s-1-0.448-1-1v-2h-3c-0.552 0-1 0.448-1 1v6c0 0.552 0.448 1 1 1h8c0.552 0 1-0.448 1-1v-6c0-0.552-0.448-1-1-1z"></path>
    </SvgIcon>
  );
};

export default Assortiment;
